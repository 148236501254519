import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import EyeSlash from 'react-icons/lib/md/visibility-off'
import Eye from 'react-icons/lib/md/visibility'

import { shouldRenderError } from 'util'
import InputError from '../InputError'
import { getErrorMessage } from 'util/form'

import queries from 'util/mediaQueries'

const ContainerInlineStyles = css`
  display: flex;
  align-items: center;

  ${queries.desktop`
    display: block;
  `};
`

const Container = styled.div`
  position: relative;
  ${props => !props.noInline && ContainerInlineStyles};
`

const InnerContainer = styled.div`
  position: relative;
  flex: 1;
`

const LabelInlineStyles = css`
  margin-right: ${props => props.theme.formInlineLabelMargin};
  margin-bottom: 0px;
  flex-basis: ${props => props.theme.formInlineOffset};
  text-align: right;

  ${queries.desktop`
    margin-bottom: 10px;
    text-align: left;
  `};
`

const Label = styled.label`
  display: block;
  font-size: 1em;
  margin-bottom: 10px;
  ${props => !props.noInline && LabelInlineStyles};

  color: ${props => (props.dark ? '#f1efed' : props.theme.colors.text.label)};
`

const Input = styled.input`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: ${props => (props.type === 'password' ? '52px' : '21px')};
  padding-left: 16px;

  &[type='select'] {
    padding-left: 0px;
  }

  background-color: ${props =>
    props.dark
      ? props.theme.colors.backgrounds.dark
      : props.theme.colors.backgrounds.normal};

  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;

  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.normal};

  border-radius: 2px;
  font-size: 1em;

  :disabled {
    background: #f1efed;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${props =>
      props.dark
        ? props.theme.colors.text.dark
        : props.theme.colors.text.normal};
    -webkit-box-shadow: 0 0 0 30px
      ${props =>
        props.dark
          ? props.theme.colors.backgrounds.dark
          : props.theme.colors.backgrounds.normal}
      inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color: ${props =>
      props.dark
        ? props.theme.colors.backgrounds.dark
        : props.theme.colors.backgrounds.normal} !important;
  }
`

const iconStyle = css`
  position: absolute;
  top: 26px;
  right: 15px;
  margin-top: -12px;
  cursor: pointer;

  color: ${props => (props.dark ? '#f1efed' : props.theme.colors.text.label)};
`

const EyeSlashIcon = styled(EyeSlash)`
  ${iconStyle};
`

const EyeIcon = styled(Eye)`
  ${iconStyle};
`

/**
 * An input component for text. Compliant with final-form props.
 *
 * ### Notice
 * Property 'value' is required by the final-form but it is not required
 * by prop-types. This allows us to use field components without final-form if
 * needed.
 *
 * - value passed, field is controlled (value is stored in final-form or in a
 * parent)
 *
 * - value not passed, field behaves like ordinary input (stores his value)
 *
 * @version 1.2.0
 * @author Stjepan Golemac
 *
 */
class TextInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPassword: props.type === 'password',
      showPassword: false,
    }

    this.toggleShowPassword = this.toggleShowPassword.bind(this)
  }

  shouldHideText() {
    const { isPassword, showPassword } = this.state

    return isPassword && !showPassword
  }

  toggleShowPassword() {
    this.setState(state => ({ ...state, showPassword: !state.showPassword }))
  }

  renderLabel() {
    const { label, noInline, dark } = this.props

    return (
      label && (
        <Label noInline={noInline} dark={dark}>
          {label}
        </Label>
      )
    )
  }

  renderInput() {
    const { props } = this
    const { isPassword } = this.state
    const hideText = this.shouldHideText()

    return (
      <Input
        {...props}
        {...(props.input || {})}
        /** We don't want to override the type in props if not necesarry */
        {...(isPassword && !hideText ? { type: 'text' } : {})}
        style={{
          paddingRight: isPassword && !hideText && '52px',
          borderBottomColor: shouldRenderError(props.meta) && '#c4944d',
          borderBottomWidth: shouldRenderError(props.meta) && '2px',
        }}
      />
    )
  }

  renderIcon() {
    const { isPassword } = this.state
    const { dark } = this.props
    const hideText = this.shouldHideText()

    if (!isPassword) {
      return null
    }

    if (hideText) {
      return (
        <EyeSlashIcon onClick={this.toggleShowPassword} dark={dark} size={24} />
      )
    }

    return <EyeIcon onClick={this.toggleShowPassword} dark={dark} size={24} />
  }

  renderError() {
    const { meta } = this.props

    if (shouldRenderError(meta)) {
      const { error, submitError } = meta

      return <InputError>{error || getErrorMessage(submitError)}</InputError>
    }

    return null
  }

  render() {
    const { noInline } = this.props

    return (
      <Container noInline={noInline}>
        {this.renderLabel()}
        <InnerContainer>
          {this.renderInput()}
          {this.renderIcon()}
          {this.renderError()}
        </InnerContainer>
      </Container>
    )
  }
}

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel']),
  noInline: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
}

TextInput.defaultProps = {
  type: 'text',
  noInline: false,
}

/** @component */
export default TextInput

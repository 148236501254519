import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { withTranslation, useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Section from 'presentational/Section'
import FormError from 'presentational/FormError'
import Paragraph from 'presentational/Paragraph'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import UserProfileForm from 'functional/UserProfileForm'
import EnableDisableUserForm from 'functional/EnableDisableUserForm'
import { NotificationContext } from 'functional/NotificationProvider'
import ConfirmPasswordResetModal from 'functional/ConfirmPasswordResetModal'

import UserContainer from 'containers/UserContainer'

import { withHandlers } from 'util/form'

const StyledButton = styled(Button)`
  width: auto;
`

const RedParagraph = styled(Paragraph)`
  color: red;
`

const EditProfileSection = () => {
  const { t } = useTranslation('common')
  return (
    <Section>
      <H2>{t('admin_user.edit_details_haeder')}</H2>
      <Paragraph>{t('admin_user.edit_details_description')}</Paragraph>
      <Subscribe to={[UserContainer]}>
        {({
          state: { getLoading, getError, details },
          updateUser,
          getUser,
          deleteUser,
        }) => {
          if (getError) return <FormError>{getError}</FormError>

          return (
            <React.Fragment>
              {getLoading && <Loader />}
              <NotificationContext.Consumer>
                {context => (
                  <UserProfileForm
                    onSubmit={withHandlers(
                      updateUser,
                      () => {
                        getUser(details['@id'], false)
                        context.methods.toggleTopNotification(
                          'success',
                          t('admin_user.edit_success')
                        )
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          t('admin_user.edit_failed')
                        )
                      }
                    )}
                    onDelete={id => deleteUser(id)}
                    data={details || {}}
                    disableEmail
                    administration
                  />
                )}
              </NotificationContext.Consumer>
            </React.Fragment>
          )
        }}
      </Subscribe>
    </Section>
  )
}

const ResetPasswordSection = () => {
  const { t, i18n } = useTranslation('common')
  return (
    <Subscribe to={[UserContainer]}>
      {({ state: { getLoading, details } }) => {
        if (getLoading || details === null) {
          return (
            <Section>
              <Loader />
            </Section>
          )
        }

        return (
          <Section>
            <H2>{t('admin_user.reset_button')}</H2>
            {details.isEnabled && (
              <Paragraph>{t('admin_user.reset_modal_description')}</Paragraph>
            )}
            {!details.isEnabled && (
              <RedParagraph>{t('admin_user.reset_modal_blocked')}</RedParagraph>
            )}
            <NotificationContext.Consumer>
              {context => (
                <StyledButton
                  disabled={!details.isEnabled}
                  onClick={() => {
                    context.methods.openModal(() => (
                      <ConfirmPasswordResetModal
                        user={details}
                        notificationContext={context}
                      />
                    ))
                  }}
                >
                  {t('admin_user.reset_button')}
                </StyledButton>
              )}
            </NotificationContext.Consumer>
          </Section>
        )
      }}
    </Subscribe>
  )
}

const EnableDisableUserSection = ({ id }) => {
  const { t } = useTranslation('common')
  return (
    <Section>
      <H2>{t('admin_user.edit_enable_disable_header')}</H2>
      <Paragraph>{t('admin_user.edit_enable_disable_description')}</Paragraph>
      <FormFieldWrapper>
        <Subscribe to={[UserContainer]}>
          {({
            state: { getLoading, updateLoading, details },
            getUser,
            toggleUser,
          }) => (
            <React.Fragment>
              <NotificationContext.Consumer>
                {context => (
                  <EnableDisableUserForm
                    onSubmit={withHandlers(
                      toggleUser,
                      () => {
                        getUser(id)
                        context.methods.toggleTopNotification(
                          'success',
                          t('admin_user.edit_success')
                        )
                      },
                      () => {
                        context.methods.toggleTopNotification(
                          'error',
                          t('admin_user.edit_failed')
                        )
                      }
                    )}
                    disableToggle={getLoading || updateLoading}
                    data={details}
                  />
                )}
              </NotificationContext.Consumer>
            </React.Fragment>
          )}
        </Subscribe>
      </FormFieldWrapper>
    </Section>
  )
}

EnableDisableUserSection.propTypes = {
  id: PropTypes.string.isRequired,
}

class EditUserScreen extends React.Component {
  componentDidMount() {
    const {
      getUser,
      match: {
        params: { id },
      },
    } = this.props

    getUser(id)
  }

  render() {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <Section>
          <H1>{t('admin_user.edit_header')}</H1>
          <Paragraph>{t('admin_user.edit_description')}</Paragraph>
        </Section>
        <EditProfileSection />
        <EnableDisableUserSection id={id} />
        <ResetPasswordSection />
      </LoggedInScreenContainer>
    )
  }
}

EditUserScreen.propTypes = {
  getUser: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
}

const EditUserScreenWrapper = props => (
  <Subscribe to={[UserContainer]}>
    {({ getUser }) => <EditUserScreen getUser={getUser} {...props} />}
  </Subscribe>
)

/** @component */
export default withTranslation('common')(EditUserScreenWrapper)

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import ListCheckboxInput from 'presentational/ListCheckboxInput'
import Button from 'presentational/Button'
import ContextMenuButton from 'presentational/ContextMenuButton'
import Dropdown from 'presentational/Dropdown'
import ListCounter from 'presentational/ListCounter'
import ToggleButton from 'presentational/ToggleButton'

const ListContainer = styled.div`
  background-color: #fff;
`

const StyledList = styled.div`
  list-style: none;
  text-decoration: none;

  padding-left: 0;
  padding-bottom: 50px;
  margin-bottom: 0;
`

const listEntryStyle = css`
  background-color: #fff;

  display: flex;
  flex-flow: row nowrap;

  border-bottom: 1px solid ${props => props.theme.colors.list.border};

  width: 100%;

  box-sizing: border-box;
`

const ListHeader = styled.div`
  ${listEntryStyle};
  padding: 16px 0 0 0;
`

const ListBody = styled.div`
  padding-left: 0px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 0;
  -webkit-flex-grow: 0;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
`

const ColumnHeading = styled.div`
  color: #f1efed;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  max-height: 50px;
`

const ListItem = styled.div`
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  padding: 2px 0;
  max-height: 50px;

  div {
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 150px;
  }
`

const ListItemProp = styled.div`
  flex-grow: 1;
  -webkit-flex-grow: 1;
  color: #0b0b0b;
  font-size: 16px;
  line-height: 40px;
`

const Column = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 1;

  margin-right: 32px;

  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;

  :first-child {
    justify-content: flex-start;
    flex-basis: 100px;
  }

  border-bottom: ${props => (props.isActive ? '3px solid' : 'none')};
  border-color: ${props => props.theme.colors.secondaryLighter};
`

const ListFooter = styled.div`
  ${listEntryStyle};
  padding: 16px 0;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.list.border};
  border-bottom: none;
`
const FooterButtonContainer = styled.div`
  max-width: 50px;
`

const SubMenuButtonContainer = styled.div`
  max-width: ;
`

const ColumnContainer = styled.div`
  display: flex;
`

const ColumnNameContainer = styled.div`
  height: 20px;

  font-weigth: normal;
  color: ${props =>
    props.isActive
      ? props.theme.colors.text.normal
      : props.theme.colors.text.label};
`

const ColumnButtonContainer = styled.div`
  height: 10px;
  cursor: pointer;
  display: block;
  margin-right: 5px;
`

const ArrowUp = styled.div`
  margin-top: -5px;

  height: 50%;
  &:after {
    content: '\\25b2';
    padding-left: 0.5em;
    font-size: 5px;
  }
`

const ArrowDown = styled.div`
  height: 50%;

  &:after {
    content: '\\25bc';
    padding-left: 0.5em;
    font-size: 5px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  margin-top: 28px;
  margin-bottom: 0;
  padding: 0 30px;
  padding-top: 25px;

  background-color: ${props => props.theme.colors.backgrounds.normal};
`

const ColumnRight = styled(Column)`
  flex-grow: 1;
  flex: 1;

  margin-left: 210px;

  justify-content: flex-end;
  div {
    justify-content: flex-end;

    div {
      justify-content: center;
    }
  }
`

const GoButton = styled(Button)`
  color: ${props => props.theme.colors.text.normal};
  background: ${props => props.theme.colors.backgrounds.button.go};

  :hover {
    background: ${props =>
      darken(0.1, props.theme.colors.backgrounds.button.go)};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: {},
      checked: false,
      data: [],
    }

    this.removeSelected = this.removeSelected.bind(this)
    this.bulkOperations = this.bulkOperations.bind(this)
    this.selectAll = this.selectAll.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        data: props.data,
      }
    }
    return null
  }

  addToSelected(item, key) {
    let selected = this.state.selected
    selected[key] = item
    this.setState({ selected }, () => {})
  }

  removeFromSelected(item, key) {
    const { selected } = this.state
    delete selected[key]
    this.setState({ selected })
  }

  exportCSV() {
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    let result, ctr, keys, output, filename, link

    const data = this.state.data.filter(i => i !== undefined)
    keys = Object.keys(data[0])
    result = ''
    result += keys.join(columnDelimiter)
    result += lineDelimiter

    data.forEach(item => {
      ctr = 0
      keys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter
        }
        result +=
          typeof item[key] === 'string' && item[key].includes(columnDelimiter)
            ? `"${item[key]}"`
            : item[key]
        ctr++
      })
      result += lineDelimiter
    })

    filename = 'contacts.csv'
    if (!result.match(/^data:text\/csv/i)) {
      result = 'data:text/csv;charset=utf-8,' + result
    }
    output = encodeURI(result)
    link = document.createElement('a')
    link.setAttribute('href', output)
    link.setAttribute('download', filename)
    link.click()
  }

  selectAll(event) {
    let { checked } = this.state
    checked = checked ? false : true
    this.setState({ checked })
    if (event.target.checked) {
      this.props.data.map((item, key) => this.addToSelected(item, key))
    } else {
      this.setState({ selected: {} })
    }
  }

  sortRows(key) {
    const data = this.state.data.sort((a, b) => {
      const x = a[key]
      const y = b[key]
      if (this.state.sortAsc) {
        return x > y ? -1 : x < y ? 1 : 0
      }
      return x < y ? -1 : x > y ? 1 : 0
    })
    this.setState({
      data,
      sortAsc: !this.state.sortAsc,
      sortKey: key,
    })
  }

  handleCheckbox(event, item, key) {
    if (event.target.checked) {
      this.addToSelected(item, key)
    } else {
      this.removeFromSelected(item, key)
    }
  }

  renderColumnHeadings(columns) {
    let HeaderColumn = Column
    return Object.keys(columns).map(key => {
      columns[key].key === 'status' || columns[key].key === 'more'
        ? (HeaderColumn = ColumnRight)
        : (HeaderColumn = Column)
      return (
        <HeaderColumn
          key={key}
          isActive={this.state.sortKey === columns[key].key}
        >
          <ColumnHeading key={key}>
            {columns[key].key === 'number' ? (
              <ListCheckboxInput
                checked={this.state.checked}
                onChange={e => this.selectAll(e)}
              />
            ) : null}
            <ColumnContainer>
              {columns[key].sortable && (
                <ColumnButtonContainer
                  onClick={() => this.sortRows(columns[key].key)}
                >
                  <ArrowUp />
                  <ArrowDown />
                </ColumnButtonContainer>
              )}
              <ColumnNameContainer
                isActive={this.state.sortKey === columns[key].key}
              >
                {columns[key].name}
              </ColumnNameContainer>
            </ColumnContainer>
          </ColumnHeading>
        </HeaderColumn>
      )
    })
  }

  renderListItem(item) {
    return Object.keys(item).map(key => {
      if (key !== 'id') {
        if (key === 'status') {
          return (
            <ColumnRight>
              <ToggleButton
                label=""
                value={item[key]}
                onChange={e => (item[key] = item[key] ? 'on' : 'off')}
              />
            </ColumnRight>
          )
        }
        return (
          <Column key={key}>
            <ListItemProp>{item[key]}</ListItemProp>
          </Column>
        )
      }
    })
  }

  renderListItems(items, context) {
    return items.map((item, key) => (
      <ListItem key={key} index={key}>
        <Column>
          <ListCheckboxInput
            checked={key in this.state.selected}
            value={key}
            onChange={e => this.handleCheckbox(e, item, key)}
          />
          {item.id}
        </Column>

        {this.renderListItem(item)}

        {context ? (
          <ColumnRight>
            <ContextMenuButton context={context} id={item.id} />
          </ColumnRight>
        ) : null}
      </ListItem>
    ))
  }

  removeSelected() {
    const { selected } = this.state
  }

  bulkOperations() {
    return {
      remove: {
        name: 'Remove selected',
        method: this.removeSelected,
      },
      suspend: {
        name: 'Suspend selected',
        method: this.suspendSelected,
      },
    }
  }

  render() {
    return (
      <React.Fragment>
        <ListContainer>
          <StyledList>
            <ListHeader>
              {this.renderColumnHeadings(this.props.columns)}
            </ListHeader>
            <ListBody>
              {this.renderListItems(this.props.data, this.props.context)}
            </ListBody>
            <ListFooter>
              <ListCheckboxInput
                checked={this.state.checked}
                onChange={this.selectAll.bind(this)}
              />
              <Dropdown
                bulkOperations={this.bulkOperations}
                toggleSelected={this.toggleSelected}
              />
              <FooterButtonContainer>
                <GoButton>Go</GoButton>
              </FooterButtonContainer>
            </ListFooter>
          </StyledList>
        </ListContainer>
      </React.Fragment>
    )
  }
}

List.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  context: PropTypes.object,
  bulkOperations: PropTypes.func,
  onChange: PropTypes.func,
}

export default List

import React from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import TextInput from 'presentational/TextInput'
import PasswordStrengthOutput from 'presentational/PasswordStrengthOutput'

import { makeMinLen, combineValidators, makeIsEqual, makeIsGreater } from 'util'

const BottomMargin = styled.div`
  margin-bottom: 20px;
`

const SmallBottomMargin = styled.div`
  margin-bottom: 5px;
`

const min8Len = makeMinLen(8)
const arePasswordsEqual = makeIsEqual(
  'plainPassword',
  'plainPasswordRepeated',
  'Passwords should be equal.'
)
const repeatedPasswordValidators = combineValidators(min8Len, arePasswordsEqual)
const strengthAcceptable = makeIsGreater(1)

/**
 * A form section that contains two fields for password and a password strenght
 * meter.
 * @author Stjepan Golemac
 */
const NewPasswordFormSection = () => {
  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <BottomMargin>
        <SmallBottomMargin>
          <Field
            dark
            name="plainPassword"
            component={TextInput}
            type="password"
            label={t('default.password')}
            placeholder={t('login_register.unique_password')}
            validate={min8Len}
            noInline
          />
        </SmallBottomMargin>
        <Field
          dark
          name="plainPasswordRepeated"
          component={TextInput}
          type="password"
          placeholder={t('login_register.confirm_password')}
          validate={repeatedPasswordValidators}
          noInline
        />
      </BottomMargin>
      <Field
        name="passwordStrength"
        component={PasswordStrengthOutput}
        validate={strengthAcceptable}
      />
    </React.Fragment>
  )
}

/** @component */
export default NewPasswordFormSection

import React from 'react'
import styled from 'styled-components'

import { formatDate } from 'util/date'

import ContextMenuButton from 'presentational/ContextMenuButton'
import Paragraph from 'presentational/Paragraph'
import H3 from 'presentational/H3'

import { withTranslation } from 'react-i18next'

const Container = styled.div`
  min-width: 330px;
  max-width: 330px;
  min-height: 330px;

  background-color: #fff;

  // border-bottom-right-radius: 18px;
  // border-top-left-radius: 18px;

  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;

  margin: 15px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`

const MetaContainer = styled.div`
  padding-bottom: 10px;
`

const ButtonContainer = styled.div`
  padding-top: 24px;
`

export const makeId = id => Number(id.split('/').pop())

const TemplateListItem = ({
  item: { '@id': id, name, desc, createdAt, updatedAt },
  context,
  t,
}) => (
  <Container>
    <TitleContainer>
      <H3>{name}</H3>
      {context && id ? (
        <ButtonContainer>
          <ContextMenuButton context={context} id={makeId(id)} />
        </ButtonContainer>
      ) : null}
    </TitleContainer>
    <MetaContainer>
      <Paragraph>{desc}</Paragraph>
      <Paragraph>{`${t('template.template_created')}${formatDate(createdAt)}${t(
        'template.template_updated'
      )}${formatDate(updatedAt)}`}</Paragraph>
    </MetaContainer>
  </Container>
)

export default withTranslation('common')(TemplateListItem)

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { shouldRenderError } from 'util'
import InputError from '../InputError'

const svgCheckIcon = require('./assets/check.png')
const svgCloseIcon = require('./assets/close.png')

const Container = styled.div`
  display: flex;
  padding-left: ${props =>
    props.statusAtLeft &&
    `
    70px;
  `};

  outline: none;
`

const OuterContainer = styled.div`
  outline: none;
  display: flex;
  flex-direction: ${props =>
    !props.statusAtLeft &&
    `
    row-reverse;
  `};
`

const LabelText = styled.div`
  margin-top: 5px;
  // margin-left: 15px;
  margin-right: 15px;
`

const StatusText = styled.div`
  margin-top: 3px;
  margin-left: 30px;
  margin-right: 70px;

  :before {
    content: '${props => props.inactive}';
  }
`

const Checkmark = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  outline: none !important;

  :before {
    position: absolute;
    height: 26px;
    width: 26px;
    left: 2px;
    top: 2px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    content: url(${svgCloseIcon});
    text-align: center;
    line-height: 2;
  }
`

const InnerContainer = styled.label`
  outline: none;
  position: relative;
  align-items: center;
  padding-left: 40px;
  width: 60px;
  height: 30px;
  outline: none;
  color: ${props => props.theme.colors.text.normal};
  user-select: none;
  cursor: pointer;

  :focus {
    outline: none;
  }

  &:hover ${Checkmark} {
    background: lightgray;
    transition: background ease 0.1s;
  }

  display: flex;
  flex-direction: ${props =>
    props.statusAtLeft &&
    `
    row-reverse;
    `};
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  outline: none;

  &:checked + ${Checkmark} {
    background-color: ${props => props.theme.colors.primary};
  }

  &:focus + ${Checkmark} {
    outline: auto 5px -webkit-focus-ring-color;
  }

  &:checked + ${Checkmark}:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    content: url(${svgCheckIcon});
    text-align: center;
    line-height: 2;
  }

  &:checked ~ ${StatusText}:before {
    content: '${props => props.active}';
  }
`

const LeftInputError = styled(InputError)`
  text-align: left;
`

/**
 * A Toggle button component with label position (left/right).
 * @version 1.0.0
 * @author Adel Memariani
 */
const ToggleButton = props => {
  const { t, i18n } = useTranslation('common')
  return (
    <Container statusAtLeft={props.statusAtLeft}>
      <OuterContainer statusAtLeft={props.statusAtLeft}>
        <InnerContainer statusAtLeft={props.statusAtLeft}>
          <Input
            type="checkbox"
            {...props}
            {...props.input}
            active={t('default.active')}
          />
          <Checkmark
            style={{
              ...(shouldRenderError(props.meta)
                ? {
                    borderBottomColor: 'red',
                    borderBottomWidth: '2px',
                  }
                : {}),
            }}
          />
          <StatusText inactive={t('default.inactive')} />
        </InnerContainer>
        {!props.statusAtLeft && <LabelText>{props.label}</LabelText>}
        {shouldRenderError(props.meta) && (
          <LeftInputError>
            {props.meta.error || props.meta.submitError}
          </LeftInputError>
        )}
      </OuterContainer>
    </Container>
  )
}

ToggleButton.propTypes = {
  label: PropTypes.string,
  statusAtLeft: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }),
}

ToggleButton.defaultProps = {
  statusAtLeft: false,
}

/** @component */
export default ToggleButton

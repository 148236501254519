import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isEmail, makeMinLen } from 'util'
import TextInput from 'presentational/TextInput'
import Button from 'presentational/Button'
import AuthFormLoader from 'presentational/AuthFormLoader'
import FormError from 'presentational/FormError'

const FieldContainer = styled.div`
  margin-bottom: 20px;
`

const ForgotPasswordLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  color: ${props => props.theme.colors.text.dark};
`

const CenteredContainer = styled(FieldContainer)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
`

const min8Len = makeMinLen(8)

/**
 * Login Form Component.
 *
 * @version 1.0
 * @author Martin Mehl
 */
const LoginForm = props => {
  const { t } = useTranslation('common')
  return (
    <Form
      onSubmit={props.onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer>
            <Field
              name="email"
              component={TextInput}
              format={(value = '') => value.trim()}
              formatOnBlur
              dark
              type="text"
              label={t('default.email')}
              validate={isEmail}
              noInline
            />
          </FieldContainer>
          <FieldContainer>
            <Field
              name="password"
              dark
              component={TextInput}
              type="password"
              label={t('default.password')}
              validate={min8Len}
              noInline
            />
          </FieldContainer>
          <CenteredContainer>
            <ForgotPasswordLink to="/forgot-password">
              {t('login_register.forgot_password')}
            </ForgotPasswordLink>
          </CenteredContainer>
          {submitting && <AuthFormLoader />}
          {submitError && (
            <FormError>{t('login_register.bad_credentials')}</FormError>
          )}
          <Button kind="bold" submit disabled={submitting} size="large">
            {t('login_register.login_button')}
          </Button>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  /** The submit handler */
  onSubmit: PropTypes.func.isRequired,
}

/** @component */
export default LoginForm

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Paragraph from 'presentational/Paragraph'

const StyledParagraph = styled(Paragraph)`
  margin: 0;
`

const ListCounter = ({ totalItems, items }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <StyledParagraph>{`${items.length} ${t(
      'default.of'
    )} ${totalItems}`}</StyledParagraph>
  )
}

export default ListCounter

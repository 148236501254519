import React from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AuthContainer from 'containers/AuthContainer'

import AuthLayout from 'presentational/AuthLayout'
import H3 from 'presentational/H3'
import Logo from 'presentational/Logo'
import AuthLogoContainer from 'presentational/AuthLogoContainer'

import LoginForm from 'functional/LoginForm'

import { withHandlers } from 'util/form'

const CreateText = styled(H3)`
  color: #f1efed;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0px;
  margin-top: 52px;
`

const BoldCreateAccountLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #f1efed;
  font-size: 22px;
  font-weight: bold;

  :hover,
  :any-link:hover {
    color: #fff;
  }

  :any-link,
  :any-link:active,
  :-webkit-any-link,
  :-webkit-any-link:active {
    color: #f1efed;
  }
`

class LoginScreen extends React.Component {
  render() {
    const {
      history: { push },
      t,
    } = this.props
    const { noRegistration } = this.props

    return (
      <AuthLayout>
        <AuthLogoContainer>
          <Logo width={'220px'} />
        </AuthLogoContainer>
        <Subscribe to={[AuthContainer]}>
          {({ login }) => (
            <LoginForm
              onSubmit={withHandlers(login, () => push('/onboarding-wizard'))}
            />
          )}
        </Subscribe>
        {!noRegistration && (
          <React.Fragment>
            <CreateText>{t('login_register.create_account_call')}</CreateText>
            <BoldCreateAccountLink to="/registration">
              {t('login_register.create_account_button')}
            </BoldCreateAccountLink>
          </React.Fragment>
        )}
      </AuthLayout>
    )
  }
}

LoginScreen.propTypes = {
  noRegistration: PropTypes.bool,
}

export default withTranslation('common')(LoginScreen)

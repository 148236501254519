import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import UserProfileForm from 'functional/UserProfileForm'
import { NotificationContext } from 'functional/NotificationProvider'

import UserContainer from 'containers/UserContainer'
import { withHandlers } from 'util/form'

const AddUserScreen = ({ history: { push } }) => {
  const { t } = useTranslation('common')
  return (
    <LoggedInScreenContainer>
      <Section>
        <H1>{t('admin_user.create_header')}</H1>
        <Paragraph>{t('admin_user.create_description')}</Paragraph>
      </Section>
      <Section>
        <H2>{t('admin_user.create_account_header')}</H2>
        <Paragraph>{t('admin_user.create_description')}</Paragraph>
        <NotificationContext.Consumer>
          {context => (
            <Subscribe to={[UserContainer]}>
              {({ createUserByAdmin }) => (
                <UserProfileForm
                  onSubmit={withHandlers(
                    createUserByAdmin,
                    () => {
                      push('/users')
                      context.methods.toggleTopNotification(
                        'success',
                        t('admin_user.create_success')
                      )
                    },
                    () => {
                      'error', t('admin_user.create_failed')
                    }
                  )}
                  create
                />
              )}
            </Subscribe>
          )}
        </NotificationContext.Consumer>
      </Section>
    </LoggedInScreenContainer>
  )
}

AddUserScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

/** @component */
export default AddUserScreen

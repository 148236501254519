import React from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import TextInput from 'presentational/TextInput'
import PrimaryButton from 'presentational/PrimaryButton'

import queries from 'util/mediaQueries'

import { withHandlers, getErrorMessage } from 'util/form'
import { isEmpty } from 'util'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 9px;

  ${queries.desktop`
    width: 100%;
  `};
`

const FieldContainer = styled.div`
  margin-bottom: 20px;
`

const DeleteUserAccountModal = ({
  onClose,
  requestDeletion,
  logout,
  toggleTopNotification,
  t,
}) => (
  <React.Fragment>
    <H3>{t('profile.delete_account_modal_header')}</H3>
    <Paragraph>{t('profile.delete_account_modal_description')}</Paragraph>
    <Form
      onSubmit={withHandlers(
        requestDeletion,
        () => {
          toggleTopNotification('success', t('profile.delete_account_success'))
          setTimeout(() => logout(), 5000)
        },
        () => toggleTopNotification('error', t('profile.delete_account_failed'))
      )}
      render={({ submitting, submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldContainer>
            <Field
              name="password"
              component={TextInput}
              type="password"
              label={t('default.password')}
              validate={isEmpty}
              noInline
            />
          </FieldContainer>
          <FormError>{getErrorMessage(submitError)}</FormError>
          <ButtonContainer>
            <PrimaryButton type="outline" submit>
              {t('profile.delete_account_modal_delete')}
            </PrimaryButton>
          </ButtonContainer>
          <ButtonContainer>
            <Button onClick={onClose} type="nobg">
              {t('profile.delete_account_modal_cancel')}
            </Button>
          </ButtonContainer>
        </form>
      )}
    />
  </React.Fragment>
)

DeleteUserAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default DeleteUserAccountModal

import React from 'react'
import PropTypes from 'prop-types'

import PasswordStrengthIndicator from '../PasswordStrengthIndicator'

/**
 * A wrapper component for PasswordStrengthIndicator for usage in final-form
 * @author Stjepan Golemac
 */
const PasswordStrengthOutput = props => (
  <PasswordStrengthIndicator
    securityRating={props.input.value || 0}
    notEnough={props.meta && !!props.meta.error}
  />
)

PasswordStrengthOutput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
}

PasswordStrengthOutput.defaultProps = {
  value: 0,
}

/** @component */
export default PasswordStrengthOutput

import styled from 'styled-components'
import { lighten } from 'polished'

/**
 * This is a paragraph.
 * @author Stjepan Golemac
 */
const Paragraph = styled.p`
  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.label};
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;

  margin-top: 0;
`

/** @component */
export default Paragraph

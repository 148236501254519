// const colors = {
//   primary: '#d36841',
//   primaryHover: '#10B10D',
//   secondary: '#4A90E2',
//   secondaryHover: '#3975BB',
//   tertiary: '#C0C0C0',
//   complement: '#c4944d',
//   text: '#554c47',
//   backgroundDark: '#554c47',
//   logo: '#fff',
//
//   button: {
//     backgrounds: {
//       normal: '#d36841',
//       dark: '#4A4A4A',
//       outline: 'rgba(255,255,255,1)',
//       nobg: 'rgba(255,255,255,1)',
//     },
//     text: {
//       normal: 'black',
//       dark: '#fff',
//     },
//   },
//
//   link: {
//     text: '#f1efed',
//     hover: '#fff',
//   },
//
//   input: {
//     label: '#f1efed',
//     backgrounds: {
//       dark: '#554c47',
//       normal: '#fff',
//     },
//     icon: '#fff',
//     text: '#fff',
//   },
//
//   backgrounds: {
//     sidebar: '#554c47',
//   },
// }

const colors = {
  primary: '#d36841',
  primaryHover: '#10B10D',
  secondary: '#4A90E2',
  secondaryHover: '#3975BB',
  secondaryLighter: '#a0b6cb',
  tertiary: '#C0C0C0',
  complement: '#c4944d',
  error: '#F73D3D',

  backgrounds: {
    normal: '#fff',
    dark: '#554c47',
    darkHover: '#443c38',
    primary: '#d36841',
    dropdown: '#e8e8e8',

    button: {
      primary: '#d36841',
      secondary: '#a0b6cb',
      complement: '#c4944d',
      dark: '#4A4A4A',
      outline: 'rgba(255,255,255,1)',
      nobg: 'rgba(255,255,255,1)',
      go: '#D7D7D7',
    },
  },

  list: {
    border: '#f1efed',
  },

  text: {
    normal: '#554c47',
    dark: 'white',
    label: '#554c47',
    light: '#f1efed',
  },
}

export default colors

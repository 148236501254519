import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import {
  isEmpty,
  makeMinLen,
  isPhoneNumber,
  combineValidators,
  makeMaxLen,
} from 'util/validations'

const PhoneFormSection = ({ required, dark, noInline }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <FormFieldWrapper>
      <Field
        name="phoneNumber"
        component={TextInput}
        type="tel"
        label={t('default.phone')}
        dark={dark}
        noInline={noInline}
        validate={
          required &&
          combineValidators(
            isEmpty,
            makeMinLen(5),
            makeMaxLen(100),
            isPhoneNumber
          )
        }
      />
    </FormFieldWrapper>
  )
}

PhoneFormSection.propTypes = {
  required: PropTypes.bool,
  dark: PropTypes.bool,
  noInline: PropTypes.bool,
}
PhoneFormSection.defaultProps = {
  required: false,
  dark: false,
  noInline: false,
}

/** @component */
export default PhoneFormSection

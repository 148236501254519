import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import {
  isEmpty,
  isFirstName,
  isLastName,
  combineValidators,
  makeMaxLen,
} from 'util/validations'

const NameFormSection = ({ required, dark, noInline }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <React.Fragment>
      <FormFieldWrapper>
        <Field
          name="firstname"
          component={TextInput}
          format={(value = '') => value.trim()}
          formatOnBlur
          type="text"
          label={t('default.firstname')}
          dark={dark}
          noInline={noInline}
          validate={
            required && combineValidators(isEmpty, isFirstName, makeMaxLen(100))
          }
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          name="lastname"
          component={TextInput}
          format={(value = '') => value.trim()}
          formatOnBlur
          type="text"
          label={t('default.lastname')}
          dark={dark}
          noInline={noInline}
          validate={
            required && combineValidators(isEmpty, isLastName, makeMaxLen(100))
          }
        />
      </FormFieldWrapper>
    </React.Fragment>
  )
}

NameFormSection.propTypes = {
  required: PropTypes.bool,
  dark: PropTypes.bool,
  noInline: PropTypes.bool,
}

NameFormSection.defaultProps = {
  required: false,
  dark: false,
  noInline: false,
}

/** @component */
export default NameFormSection

import React from 'react'
import styled from 'styled-components'

import Navigation from 'functional/Navigation'
import SidebarProfileBadge from 'presentational/SidebarProfileBadge'

import Logo from 'presentational/Logo'

const LogoContainer = styled.div`
  color: ${props => props.theme.colors.logo};
  font-size: 3em;
  font-weight: normal;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  margin: 27px 25px;
`

const Container = styled.div`
  position: relative;
  height: 100%;

  width: 320px;

  background-color: ${props => props.theme.colors.backgrounds.dark};
  overflow-y: hidden;
  overflow-x: hidden;
`

/**
 * The app sidebar component
 * @author Martin Mehl
 */
const Sidebar = props => {
  return (
    <Container>
      <LogoContainer>
        <Logo width={'120px'} />
      </LogoContainer>
      <Navigation targets={props.targets} />
      <SidebarProfileBadge />
    </Container>
  )
}

/** @component */
export default Sidebar

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import SelectInput from 'presentational/SelectInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import { isSelected } from 'util/validations'

const GenderFormSection = ({ required, dark, noInline }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <FormFieldWrapper maxWidth="320px">
      <Field
        name="sex"
        render={({ input, meta, options, label }) => (
          <SelectInput
            input={input}
            meta={meta}
            options={options}
            label={label}
            dark={dark}
            noInline={noInline}
            onChange={input.onChange}
          />
        )}
        type="text"
        label={t('default.title')}
        options={[
          { label: t('default.select'), value: '' },
          { label: t('default.ms'), value: 'female' },
          { label: t('default.mr'), value: 'male' },
          { label: t('default.mx'), value: 'divers' },
        ]}
        validate={required && isSelected}
      />
    </FormFieldWrapper>
  )
}

GenderFormSection.propTypes = {
  required: PropTypes.bool,
  dark: PropTypes.bool,
  noInline: PropTypes.bool,
}

GenderFormSection.defaultProps = {
  required: false,
  dark: false,
  noInline: false,
}

/** @component */
export default GenderFormSection

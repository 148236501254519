import React, { useState } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Logo from 'presentational/Logo'
import Paragraph from 'presentational/Paragraph'
import AuthLayout from 'presentational/AuthLayout'

import BackButton from 'functional/BackButton'
import ForgotPasswordForm from 'functional/ForgotPasswordForm'

import AccountContainer from 'containers/AccountContainer'

import { withHandlers } from 'util/form'

const HeadingContainer = styled.div`
  margin-bottom: 40px;
`

const MessageContainer = styled.div`
  margin-bottom: 30px;
`

const LogoContainer = styled.div`
  margin-top: 40px;
`

const ForgotPasswordScreen = props => {
  const {
    history: { push },
  } = props
  const [success, setSuccess] = useState(false)

  const { t } = useTranslation('common')

  return (
    <AuthLayout>
      <BackButton dark />
      <LogoContainer>
        <Logo width={'220px'} />
      </LogoContainer>
      <HeadingContainer>
        <H1 dark>{t('login_register.reset_password_header')}</H1>
      </HeadingContainer>
      {!success && [
        <MessageContainer>
          <Paragraph dark>
            {t('login_register.reset_password_description')}
          </Paragraph>
        </MessageContainer>,
        <Subscribe to={[AccountContainer]}>
          {({ requestNewPassword }) => (
            <ForgotPasswordForm
              onSubmit={withHandlers(requestNewPassword, () =>
                setSuccess(true)
              )}
            />
          )}
        </Subscribe>,
      ]}
      {success && (
        <div>
          <MessageContainer>
            <Paragraph dark>
              {t('login_register.reset_password_success')}
            </Paragraph>
          </MessageContainer>
        </div>
      )}
    </AuthLayout>
  )
}

export default ForgotPasswordScreen

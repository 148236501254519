import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * A wrapper for form fields.
 * @author Stjepan Golemac
 */
const FormFieldWrapper = styled.div`
  margin-bottom: 20px;
  max-width: ${props => props.maxWidth};
`

FormFieldWrapper.propTypes = {
  maxWidth: PropTypes.string,
}

FormFieldWrapper.defaultProps = {
  maxWidth: '540px',
}

/** @component */
export default FormFieldWrapper

import { Container } from 'unstated'

import i18n from '../translation/i18n'

import { apiClient, withoutAuth, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

class AccountContainer extends Container {
  state = {
    profileLoading: false,
    profileData: null,
    profileError: null,

    updateProfileLoading: false,
    updateProfileError: null,

    requestNewPasswordLoading: false,
    requestNewPasswordError: null,

    requestEmailChangeLoading: false,
    requestEmailChangeError: null,

    requestDeletionLoading: false,
    requestDeletionError: null,
  }

  getProfileData = async () => {
    try {
      await this.setState({ profileLoading: true, profileError: null })

      const res = await apiClient.get('me')

      const profileData = res.data

      i18n.changeLanguage(profileData.language)

      await this.setState({ profileLoading: false, profileData })
    } catch (e) {
      console.warn('in getProfileData', e)

      await this.setState({
        profileLoading: false,
        profileError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateProfileData = async data => {
    try {
      await this.setState({
        updateProfileLoading: true,
        updateProfileError: null,
      })

      // when the user is updated without avatar change the data structure so the api can find the file
      /*
      if (data.avatar && data.avatar.name && !data.avatarFilename) {
        data.avatarFilename = data.avatar.name
      }
      */

      await apiClient.put(`users/${cleanId(data['@id'])}`, { ...data })

      this.getProfileData()

      await this.setState({ updateProfileLoading: false })
    } catch (e) {
      console.warn('in updateProfileData', e)

      await this.setState({
        updateProfileLoading: false,
        updateProfileError: handleFailure(e, true),
      })

      throw e
    }
  }

  register = async data => {
    try {
      return apiClient.post('register', { ...data }, withoutAuth())
    } catch (e) {
      console.warn('in register', e)
      throw e
    }
  }

  confirmAccount = async data => {
    try {
      await apiClient.put(`confirm-account`, { ...data }, withoutAuth())
    } catch (e) {
      console.warn('in confirmEmail', e)
      throw e
    }
  }

  requestNewPassword = async data => {
    try {
      await this.setState({
        requestNewPasswordLoading: true,
        requestNewPasswordError: null,
      })

      await apiClient.put(
        'request-new-password',
        { email: data.email },
        withoutAuth()
      )

      await this.setState({ requestNewPasswordLoading: false })
    } catch (e) {
      console.warn('in requestNewPassword', e)

      await this.setState({
        requestNewPasswordLoading: false,
        requestNewPasswordError: handleFailure(e, true),
      })

      throw e
    }
  }

  setNewPassword = async data => {
    try {
      await apiClient.put('set-new-password', { ...data }, withoutAuth())
    } catch (e) {
      console.warn('in setNewPassword', e)
      throw e
    }
  }

  requestEmailChange = async data => {
    try {
      await this.setState({
        requestEmailChangeLoading: true,
        requestEmailChangeError: null,
      })

      await apiClient.put('me/change-email', { email: data.email })

      await this.setState({ requestEmailChangeLoading: false })
    } catch (e) {
      console.warn('in requestEmailChange', e)

      await this.setState({
        requestEmailChangeLoading: false,
        requestEmailChangeError: handleFailure(e, true),
      })

      throw e
    }
  }

  confirmEmail = async data => {
    try {
      await apiClient.put(`confirm-email`, { ...data }, withoutAuth())
    } catch (e) {
      console.warn('in confirmEmail', e)
      throw e
    }
  }

  requestDeletion = async data => {
    try {
      await this.setState({
        requestDeletionLoading: true,
        requestDeletionError: null,
      })

      await apiClient.post('users/deletion-request', { ...data })

      await this.setState({ requestDeletionLoading: false })
    } catch (e) {
      console.warn(e)

      await this.setState({
        requestDeletionLoading: false,
        requestDeletionError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default AccountContainer

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Subscribe } from 'unstated'
import { withTranslation, useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Button from 'presentational/Button'
import Loader from 'presentational/Loader'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import SelectInput from 'presentational/SelectInput'
import DangerButton from 'presentational/DangerButton'
import PrimaryButton from 'presentational/PrimaryButton'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import UserProfileForm from 'functional/UserProfileForm'
import DeleteUserAccountModal from 'functional/DeleteUserAccountModal'
import ConfirmEmailChangeModal from 'functional/ConfirmEmailChangeModal'
import FileInput from 'functional/FileInput'

import AccountContainer from 'containers/AccountContainer'
import AuthContainer from 'containers/AuthContainer'

import { NotificationContext } from 'functional/NotificationProvider'
import { withHandlers } from 'util/form'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
} from 'presentational/ScreenLayoutContainers'

const ContentContainerWithoutPadding = styled(ContentContainer)`
  padding-left: 0;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  margin: 40px 0;
`

const ButtonContainer = styled.div`
  display: inline-block;
`

const LoaderWithLeftMargin = styled(Loader)`
  margin-left: 9px;
`

const AutoWithButton = styled(DangerButton)`
  width: auto;
`

const FormWrapper = styled.div`
  width: 200px;
`

const StyledButton = styled(Button)`
  margin-top: 25px;
`

const AvatarSection = ({ updateProfileData, profileData, profileLoading }) => {
  const { t } = useTranslation('common')
  const [uploadedImage, setUploadedImage] = useState(null)

  let profileAvatar

  if (profileData && profileData.avatar) {
    profileAvatar = `data:${profileData.avatar.mimetype};base64,${profileData.avatar.base64}`
  }

  if (uploadedImage !== null) {
    profileAvatar = uploadedImage
  }

  return (
    <NotificationContext.Consumer>
      {context => (
        <Section>
          <H2>{t('profile.avatar_title')}</H2>
          <Paragraph>{t('profile.avatar_description')}</Paragraph>
          <FileInput
            file={profileData ? profileAvatar : null}
            fileName={
              profileData && profileData.avatar ? profileData.avatar.name : null
            }
            crop={{ active: true, ratio: 1 / 1 }}
            accept="image/png, image/jpeg"
            loading={profileLoading}
            onUpload={(imageName, imageFile) =>
              updateProfileData({ ...profileData, avatarFilename: imageName })
                .then(() => {
                  setUploadedImage(imageFile)
                  context.methods.toggleTopNotification(
                    'success',
                    t('profile.update_avatar_success')
                  )
                })
                .catch(() =>
                  context.methods.toggleTopNotification(
                    'error',
                    t('profile.update_avatar_failed')
                  )
                )
            }
            onDelete={() =>
              updateProfileData({
                ...profileData,
                avatarFilename: 'AVATAR_DELETE',
                avatar: null,
              })
                .then(() => {
                  setUploadedImage(null)
                  context.methods.toggleTopNotification(
                    'success',
                    t('profile.remove_avatar_success')
                  )
                })
                .catch(() =>
                  context.methods.toggleTopNotification(
                    'error',
                    t('profile.remove_avatar_failed')
                  )
                )
            }
            onUploadFailed={() =>
              context.methods.toggleTopNotification(
                'error',
                t('profile.upload_avatar_failed')
              )
            }
          ></FileInput>
        </Section>
      )}
    </NotificationContext.Consumer>
  )
}

const EditProfileSection = ({ onDeleteSuccess }) => {
  const { t } = useTranslation('common')

  return (
    <Section>
      <H2>{t('profile.contact_title')}</H2>
      <Paragraph>{t('profile.contact_description')}</Paragraph>
      <NotificationContext.Consumer>
        {context => (
          <Subscribe to={[AccountContainer, AuthContainer]}>
            {(
              {
                state: {
                  profileData,
                  profileLoading,
                  profileError,
                  requestDeletionLoading,
                },
                updateProfileData,
                requestDeletion,
              },
              { logout }
            ) => {
              if (profileLoading) {
                return (
                  <LoaderContainer>
                    <Loader size="large" />
                  </LoaderContainer>
                )
              }

              if (profileError) {
                return <FormError>{profileError}</FormError>
              }

              if (!profileData) {
                return null
              }

              return (
                <UserProfileForm
                  onSubmit={withHandlers(
                    updateProfileData,
                    () =>
                      context.methods.toggleTopNotification(
                        'success',
                        t('profile.update_account_success')
                      ),
                    () =>
                      context.methods.toggleTopNotification(
                        'error',
                        t('profile.update_account_failed')
                      )
                  )}
                  onDelete={() =>
                    context.methods.openModal(() => (
                      <DeleteUserAccountModal
                        t={t}
                        toggleTopNotification={
                          context.methods.toggleTopNotification
                        }
                        requestDeletion={requestDeletion}
                        logout={logout}
                        onClose={() => context.methods.closeModal()}
                      />
                    ))
                  }
                  deleteLoading={requestDeletionLoading}
                  data={profileData}
                  disableEmail
                />
              )
            }}
          </Subscribe>
        )}
      </NotificationContext.Consumer>
    </Section>
  )
}

EditProfileSection.propTypes = {
  onDeleteSuccess: PropTypes.func.isRequired,
}

const ChangePasswordSection = () => {
  const { t } = useTranslation('common')

  return (
    <Section>
      <H2>{t('profile.change_password_title')}</H2>
      <Paragraph>{t('profile.change_password_description')}</Paragraph>
      <NotificationContext.Consumer>
        {context => (
          <Subscribe to={[AccountContainer]}>
            {({
              state: {
                profileData,
                profileLoading,
                profileError,
                requestNewPasswordLoading,
                requestNewPasswordError,
              },
              requestNewPassword,
            }) => (
              <React.Fragment>
                <ButtonContainer>
                  <PrimaryButton
                    disabled={
                      profileLoading ||
                      profileError ||
                      requestNewPasswordLoading ||
                      requestNewPasswordError
                    }
                    onClick={() =>
                      requestNewPassword(profileData)
                        .then(() =>
                          context.methods.toggleTopNotification(
                            'success',
                            t('profile.change_password_success')
                          )
                        )
                        .catch(() =>
                          context.methods.toggleTopNotification(
                            'success',
                            t('profile.change_password_failed')
                          )
                        )
                    }
                  >
                    {t('profile.change_password_button')}
                  </PrimaryButton>
                </ButtonContainer>
                {(profileLoading || requestNewPasswordLoading) && (
                  <LoaderWithLeftMargin />
                )}
                {(profileError || requestNewPasswordError) && (
                  <FormError>
                    {profileError || requestNewPasswordError}
                  </FormError>
                )}
              </React.Fragment>
            )}
          </Subscribe>
        )}
      </NotificationContext.Consumer>
    </Section>
  )
}

const ChangeEmailSection = () => {
  const { t } = useTranslation('common')

  return (
    <Section>
      <H2>{t('profile.change_email_title')}</H2>
      <Paragraph>{t('profile.change_email_description')}</Paragraph>
      <NotificationContext.Consumer>
        {context => (
          <ButtonContainer>
            <PrimaryButton
              onClick={() =>
                context.methods.openModal(() => (
                  <ConfirmEmailChangeModal
                    onClose={context.methods.closeModal}
                    onSuccess={() => {
                      context.methods.closeModal()

                      context.methods.toggleTopNotification(
                        'success',
                        t('profile.change_email_success')
                      )
                    }}
                    onFail={() => {
                      context.methods.closeModal()

                      context.methods.toggleTopNotification(
                        'error',
                        t('profile.change_email_failed')
                      )
                    }}
                  />
                ))
              }
            >
              {t('profile.change_email_button')}
            </PrimaryButton>
          </ButtonContainer>
        )}
      </NotificationContext.Consumer>
    </Section>
  )
}

export const LanguageSection = () => {
  const { t, i18n } = useTranslation('common')
  const [language, setLanguage] = useState()
  return (
    <Subscribe to={[AccountContainer]}>
      {({
        state: { profileLoading, profileError, profileData },
        updateProfileData,
      }) => {
        if (profileLoading || profileData === null) return <Loader />
        if (profileError) return profileError

        return (
          <ContentContainerWithoutPadding>
            <Section>
              <H2>{t('profile.language_header')}</H2>
              <Paragraph>{t('profile.language_description')}</Paragraph>
              <FormWrapper>
                <NotificationContext.Consumer>
                  {context => (
                    <React.Fragment>
                      <SelectInput
                        options={[
                          { label: t('default.german'), value: 'german' },
                          { label: t('default.english'), value: 'english' },
                        ]}
                        input={{
                          onChange: e => setLanguage(e.target.value),
                          name: 'language',
                          value: language ? language : profileData.language,
                        }}
                      />
                      <StyledButton
                        onClick={withHandlers(
                          () =>
                            updateProfileData({
                              ...profileData,
                              language: language
                                ? language
                                : profileData.language,
                            }),
                          () => {
                            context.methods.toggleTopNotification(
                              'success',
                              t('profile.language_change_success')
                            )
                            i18n.changeLanguage(
                              language ? language : profileData.language
                            )
                          },
                          () => {
                            context.methods.toggleTopNotification(
                              'error',
                              t('profile.language_change_failed')
                            )
                          }
                        )}
                      >
                        {t('profile.language_change')}
                      </StyledButton>
                    </React.Fragment>
                  )}
                </NotificationContext.Consumer>
              </FormWrapper>
            </Section>
          </ContentContainerWithoutPadding>
        )
      }}
    </Subscribe>
  )
}

class EditProfileScreen extends React.Component {
  componentDidMount() {
    this.props.getProfileData()
  }

  render() {
    const {
      history: { push },
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('profile.title')}</H1>
          <HeaderButtonContainer>
            <NavLink to="/settings">
              <Button kind="secondary">
                {this.props.t('default.back_to_overview')}
              </Button>
            </NavLink>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('profile.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentContainerWithoutPadding>
          <AvatarSection
            profileData={this.props.profileData}
            updateProfileData={this.props.updateProfileData}
            profileLoading={this.props.profileLoading}
            onUpdate={this.props.updateProfileData}
          />
        </ContentContainerWithoutPadding>
        <ContentContainerWithoutPadding>
          <EditProfileSection onDeleteSuccess={() => push('/logout')} />
        </ContentContainerWithoutPadding>
        <ContentContainerWithoutPadding>
          <ChangePasswordSection />
        </ContentContainerWithoutPadding>
        <ContentContainerWithoutPadding>
          <ChangeEmailSection />
        </ContentContainerWithoutPadding>
        <LanguageSection />
      </LoggedInScreenContainer>
    )
  }
}

EditProfileScreen.propTypes = {
  getProfileData: PropTypes.func.isRequired,
}

const EditProfileWrapper = props => (
  <Subscribe to={[AccountContainer]}>
    {({
      getProfileData,
      updateProfileData,
      state: { profileData, profileLoading },
    }) => (
      <EditProfileScreen
        {...props}
        getProfileData={getProfileData}
        updateProfileData={updateProfileData}
        profileData={profileData}
        profileLoading={profileLoading}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(EditProfileWrapper)

import React from 'react'
import styled from 'styled-components'

const InnerContainer = styled.label`
  margin-right: 16px;
  align-items: center;
  display: flex;
`

const Input = styled.input`
  ${'' /* appearance: none; */} height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #d7d7d7;
  outline: none;
`

const Checkmark = styled.span`
  :after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const ListCheckboxInput = props => (
  <InnerContainer>
    <Input type="Checkbox" {...props} />
    <Checkmark />
  </InnerContainer>
)

export default ListCheckboxInput

import React from 'react'

import NavigationList from 'functional/NavigationList'

class Navigation extends React.Component {
  render() {
    return <NavigationList targets={this.props.targets} />
  }
}

export default Navigation

import React from 'react'
import styled from 'styled-components'

import TopNotification from 'presentational/TopNotification'
import Modal from 'presentational/Modal'

const Wrapper = styled.div`
  height: 100%;
`

class NotificationProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      topNotification: {
        isOpen: false,
        message: 'default notification text',
        type: 'success',
      },
      modal: {
        isOpen: false,
        component: null,
      },
    }

    this.toggleTopNotification = this.toggleTopNotification.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  toggleTopNotification(
    type = this.state.topNotification.type,
    message = this.state.topNotification.message
  ) {
    const topNotification = { ...this.state.topNotification }
    topNotification.isOpen = !topNotification.isOpen ? true : false
    topNotification.message = message
    topNotification.type = type
    this.setState({ topNotification })
    this.autoCloseTopNotification()
  }

  autoCloseTopNotification() {
    setTimeout(() => {
      const topNotification = { ...this.state.topNotification }
      topNotification.isOpen = false
      this.setState({ topNotification })
    }, 3000)
  }

  openModal(component = this.state.modal.component) {
    const modal = { ...this.state.modal }
    modal.isOpen = !modal.isOpen ? true : false
    modal.component = component
    this.setState({ modal })
  }

  closeModal() {
    const modal = { ...this.state.modal }
    modal.isOpen = false
    this.setState({ modal })
  }

  getMethods() {
    return {
      closeModal: this.closeModal,
      openModal: this.openModal,
      toggleTopNotification: this.toggleTopNotification,
    }
  }

  render() {
    const { isLoggedIn } = this.props

    return (
      <NotificationContext.Provider
        value={{
          state: this.state,
          methods: this.getMethods(),
        }}
      >
        <Modal modalSlot={this.state.modal.component} />
        <Wrapper>
          <TopNotification isLoggedIn={isLoggedIn} />
          {this.props.children}
        </Wrapper>
      </NotificationContext.Provider>
    )
  }
}

/** @component */
export default NotificationProvider

/** @context */
export const NotificationContext = React.createContext()

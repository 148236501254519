import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const sizes = {
  small: 1,
  normal: 1,
  large: 2,
}

function size(props) {
  return sizes[props.size] || sizes.normal
}

function sizeTenth(props) {
  return (sizes[props.size] || sizes.normal) / 10
}

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
/**
 * Loader component
 * @author Stjepan Golemac
 */
const Loader = styled.div`
  display: inline-block;
  border: ${sizeTenth}em solid ${props => props.theme.colors.primary};
  border-top: ${sizeTenth}em solid transparent;
  border-radius: 50%;
  width: ${size}em;
  height: ${size}em;
  animation: ${rotate} 1s infinite linear;
`

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']),
}

Loader.defaultProps = { size: 'normal' }

/** @component */
export default Loader

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Paragraph from 'presentational/Paragraph'

import i18n from '../../../translation/i18n'

const GaugeContainer = styled.div`
  display: flex;
  margin: 0px -3px;
`

const PartContainer = styled.div`
  height: 6px;
  width: 25%;
`

const PartInner = styled.div`
  background: ${props => props.theme.colors.text.normal};
  height: 100%;
  margin: 0px 3px;
  border-radius: 3px;
`

const GaugePart = () => (
  <PartContainer>
    <PartInner />
  </PartContainer>
)

const SmallParagraph = styled(Paragraph)`
  color: ${props => (props.critical ? '#c4944d' : undefined)};
  margin-top: 5px;
  font-size: 0.8em;
`

const Rating = styled.b`
  font-weight: bold;
  color: ${props => (props.critical ? '#c4944d' : undefined)};
`

const words = [
  '¯\\_(ツ)_/¯',
  i18n.getFixedT(null, 'common')('form.passwords_unsafe'),
  i18n.getFixedT(null, 'common')('form.passwords_acceptable'),
  i18n.getFixedT(null, 'common')('form.passwords_respectable'),
  i18n.getFixedT(null, 'common')('form.passwords_unbreakable'),
]

/**
 * Component that shows password strength
 * @author Stjepan Golemac
 */
const PasswordStrengthIndicator = ({ securityRating, notEnough }) => {
  const { t, i18n } = useTranslation('common')

  return (
    !!securityRating && (
      <div>
        <GaugeContainer>
          {Array.apply(null, Array(securityRating)).map((_, i) => (
            <GaugePart key={i} />
          ))}
        </GaugeContainer>
        <SmallParagraph critical={notEnough}>
          {t('form.passwords_strength')}{' '}
          <Rating critical={notEnough}>{words[securityRating]}</Rating>
        </SmallParagraph>
      </div>
    )
  )
}

PasswordStrengthIndicator.propTypes = {
  securityRating: PropTypes.oneOf([0, 1, 2, 3, 4]),
  notEnough: PropTypes.bool,
}

PasswordStrengthIndicator.defaultProps = {
  securityRating: 0,
}

/** @component */
export default PasswordStrengthIndicator

import styled from 'styled-components'

/**
 * A common error component for form inputs.
 * @author Stjepan Golemac
 */
const InputError = styled.label`
  display: block;
  width: 100%;
  margin-top: 5px;
  font-size: 0.9em;
  text-align: right;
  color: ${props => props.theme.colors.complement};
`

/** @component */
export default InputError

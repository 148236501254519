import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field, FormSpy } from 'react-final-form'

import Loader from 'presentational/Loader'
import ToggleButton from 'presentational/ToggleButton'

const EnableDisableUserForm = ({ onSubmit, disableToggle, data }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={data}
    render={({ submitting, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormSpy
          subscription={{ values: true, dirty: true }}
          onChange={({ dirty, values }) => {
            dirty && values.isEnabled !== undefined && handleSubmit()
          }}
        />
        <Field
          name="isEnabled"
          component={ToggleButton}
          type="checkbox"
          disabled={disableToggle}
        />
        {submitting && <Loader />}
      </form>
    )}
  />
)

EnableDisableUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disableToggle: PropTypes.bool,
  data: PropTypes.any,
}

/** @component */
export default EnableDisableUserForm

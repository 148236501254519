import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import KeyboardArrowRight from 'react-icons/lib/md/keyboard-arrow-right'
import AreaChart from 'react-icons/lib/fa/area-chart'
import PaperPlane from 'react-icons/lib/fa/paper-plane'
import Tags from 'react-icons/lib/fa/tags'
import Leads from 'react-icons/lib/fa/user'
import Crosshairs from 'react-icons/lib/fa/crosshairs'
import Settings from 'react-icons/lib/fa/cogs'
import Support from 'react-icons/lib/fa/question-circle-o'

import designer from './menu-icons/designer-white.svg'
import template from './menu-icons/template-white.svg'
import clock from './menu-icons/clock-white.svg'
import user from './menu-icons/user-white.svg'

import { NavLink, withRouter } from 'react-router-dom'
import NavigationSubmenuLink from 'presentational/NavigationSubmenuLink'

const List = styled.ul`
  list-style: none;
  margin: 0 auto;

  width: 320px;

  padding-left: 10px;

  overflow: hidden;

  padding-top: 5px;
  margin-top: -5px;

  padding-bottom: 5px;
  margin-bottom: -5px;
`
const SubList = styled(List)`
  margin-top: 7px;
  padding: 0 0 0 30px;

  li {
    margin-top: 0;
    padding-top: 0px;
  }
`
const NavigationItem = styled.li`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;

  overflow: visible;
  margin-right: 10px;

  padding: 0 0 10px 0;

  ul {
    height: 0;
  }

  ul li {
    padding-bottom: 0;
  }

  ul li  {
    padding: 0 40px;
    line-height: 26px;
    font-size: 16px
    color: ${props => props.theme.colors.text.label}
  }

  ul li .active {
    color: #fff;
  }
`

const activeClassName = 'nav-item-active'
const NavigationLink = styled(NavLink).attrs({
  activeClassName,
})`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  cursor: pointer;

  padding: 7px 30px;

  border-radius: 5px;

  width: 100%;
  display: flex;
  align-items: center;

  transition: all 0.2s ease-in-out;

  :hover {
    color: ${props => props.theme.colors.secondaryLighter};
  }

  img:first-child {
    margin-right: 14px;
  }

  &.${activeClassName} {
    background-color: ${props => props.theme.colors.backgrounds.darkHover};
    color: ${props => props.theme.colors.secondaryLighter};

    svg:last-child {
      position: absolute;
      right: 20px;
      color: #fff;
    }

    + ul {
      height: auto;
    }
  }
`

const ArrowRight = styled(KeyboardArrowRight)`
  margin-left: 20px;
  margin-bottom: -4px;
`

export const navigationIcon = icon => (
  <img src={icon} width="26px" height="26px" />
)

class NavigationList extends React.Component {
  renderIcon(name) {
    switch (name) {
      case 'Dashboard':
        return this.getFaIcon(AreaChart)
      case 'Contacts':
        return this.getFaIcon(Leads)
      case 'Designer':
        return navigationIcon(designer)
      case 'Templates':
        return navigationIcon(template)
      case 'Statistics':
        return navigationIcon(clock)
      case 'Settings':
        return this.getFaIcon(Settings)
      case 'User':
        return navigationIcon(user)
      case 'Websites':
        return this.getFaIcon(Crosshairs)
      case 'Content Assets':
        return this.getFaIcon(PaperPlane)
      case 'Labels':
        return this.getFaIcon(Tags)
      case 'Support':
        return this.getFaIcon(Support)
    }
  }

  getFaIcon(icon) {
    const Element = styled(icon)`
      margin-right: 20px;
      margin-bottom: -4px;
      color: white !important;
    `;
    return <Element />
  }

  renderSubmenuItem(key, submenu) {
    const item = submenu[key]
    const { t } = this.props

    return (
      <NavigationItem key={key}>
        <NavigationSubmenuLink to={item.route}>
          {t(`navigation.${item.language_key}`)}
        </NavigationSubmenuLink>
      </NavigationItem>
    )
  }
  renderSubmenu(submenu) {
    if (submenu === {}) return false

    return (
      <SubList>
        {Object.keys(submenu).map(key => this.renderSubmenuItem(key, submenu))}
      </SubList>
    )
  }
  renderNavigationItem(key) {
    const items = { ...this.props.targets }
    const { t } = this.props
    const submenu = items[key].submenu ? items[key].submenu : {}

    let isActive = !window.location.pathname.search(items[key].route)

    if (key === 'default') return

    return (
      <NavigationItem key={key} activeClassName={activeClassName}>
        <NavigationLink activeClassName={activeClassName} to={items[key].route}>
          {this.renderIcon(items[key].name)}
          {t(`navigation.${items[key].language_key}`)}
          {isActive ? <ArrowRight /> : null}
        </NavigationLink>
        {this.renderSubmenu(submenu)}
      </NavigationItem>
    )
  }

  render() {
    return (
      <List>
        {Object.keys(this.props.targets).map(key =>
          this.renderNavigationItem(key)
        )}
      </List>
    )
  }
}

NavigationList.propTypes = {
  targets: PropTypes.object.isRequired,
}

export default withRouter(withTranslation('common')(NavigationList))

import axios from 'axios'

import { getToken, refreshIt } from './tokens'
import { replaceObjKeys } from './object'

export const URL =
  window.config !== undefined && window.config.API_URL
    ? window.config.API_URL
    : process.env.API_URL

export const apiClient = axios.create({
  baseURL: URL + '/v1/',
  timeout: 100000,
  /*
  transformRequest: [
    data => JSON.stringify(replaceObjKeys(key => key.replace('_', '@'), data)),
  ],
  */
})

apiClient.defaults.headers.post['Content-Type'] = 'application/json'
apiClient.defaults.headers.put['Content-Type'] = 'application/json'

getToken().then(
  token =>
    (apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token)
)

// https://gist.github.com/tesarwijaya/bf05f4b8c8a3136e72f46253b56285fc
apiClient.interceptors.response.use(
  /*
  response => {
    if (
      response.headers['content-type'] &&
      response.headers['content-type'].includes('application/ld+json')
    ) {
      response.data = replaceObjKeys(
        key => key.replace('@', '_'),
        response.data
      )
      console.log(response.data)
    }

    return response
  },
  */
  response => response,
  async error => {
    try {
      const originalRequest = error.config

      if (error.config.url.includes('login_check')) {
        throw error
      }

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true

        const newToken = await refreshIt()

        apiClient.defaults.headers.common['Authorization'] =
          'Bearer ' + newToken
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken

        return apiClient(originalRequest)
      }

      throw error
    } catch (error) {
      console.warn('api.js', error)
      return Promise.reject(error)
    }
  }
)

export function withoutAuth(config = {}) {
  return {
    ...config,
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization

        if (typeof data === 'object') {
          return JSON.stringify(data)
        }

        return data
      },
    ],
  }
}

export function cleanId(id) {
  if (id !== undefined && isNaN(id) && id.includes('/')) {
    return id.split('/').slice(-1)[0]
  } else {
    return id
  }
}

export function wrapId(id, entity) {
  return `v1/${entity}/${id}`
}

export function readMutationError(e) {
  return e.response.data['hydra:description'] || e.message || 'unknown'
}

export function base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

import styled from 'styled-components'

/**
 * A wrapper for common screens when the user is logged in.
 * @author Stjepan Golemac
 */
const LoggedInScreenContainer = styled.div`
  min-height: 100%;
  background: #f1efed;
`

/** @component */
export default LoggedInScreenContainer

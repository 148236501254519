import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavigationSubmenuLink = styled(NavLink)`
  color: #f1efed;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;

  &.active {
    color: #fff;
  }

  :hover {
    color: #fff;
  }
`

/** @component */
export default NavigationSubmenuLink

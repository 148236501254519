import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { withTranslation, useTranslation } from 'react-i18next'
import { Form, Field, FormSpy } from 'react-final-form'

import { isEmail, required } from 'util/validations'
import { withHandlers } from 'util/form'

import H1 from 'presentational/H1'
import H2 from 'presentational/H2'
import Loader from 'presentational/Loader'
import Button from 'presentational/Button'
import Section from 'presentational/Section'
import Paragraph from 'presentational/Paragraph'
import FormError from 'presentational/FormError'
import TextInput from 'presentational/TextInput'
import PrimaryButton from 'presentational/PrimaryButton'
import FormFieldWrapper from 'presentational/FormFieldWrapper'
import FormInlineWrapper from 'presentational/FormInlineWrapper'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import {
  HeaderContainer,
  SubHeaderContainer,
  ContentContainer,
} from 'presentational/ScreenLayoutContainers'

import { NotificationContext } from 'functional/NotificationProvider'

import AppSettingsContainer from 'containers/AppSettingsContainer'

const ContentContainerWithoutPadding = styled(ContentContainer)`
  padding-left: 0;
`

const WrapButton = styled(Button)`
  width: auto;
  margin-right: 5px;
`

const LoaderRightMargin = styled(Loader)`
  margin-right: 10px;
`

const EmailSection = ({ t, initialValues, onSubmit = () => {} }) => {
  return (
    <Section>
      <H2>{t('backend_configuration.content_asset_title')}</H2>
      <Paragraph>
        {t('backend_configuration.content_asset_description')}
      </Paragraph>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <FormFieldWrapper>
              <Field
                name={'email_from'}
                component={TextInput}
                format={(value = '') => value.trim()}
                formatOnBlur
                type="email"
                label={t('backend_configuration.content_asset_from_label')}
                validate={required && isEmail}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <Field
                name={'reply_to'}
                component={TextInput}
                format={(value = '') => value.trim()}
                formatOnBlur
                type="email"
                label={t('backend_configuration.content_asset_reply_to_label')}
                validate={required && isEmail}
              />
            </FormFieldWrapper>
            <div>
              <FormInlineWrapper>
                {submitError && <FormError>{submitError}</FormError>}
              </FormInlineWrapper>
              <FormSpy
                subscription={{ values: true }}
                render={({ values }) => (
                  <WrapButton type="submit" disabled={submitting}>
                    {t('default.save_changes')}
                  </WrapButton>
                )}
              />
              {submitting && <LoaderRightMargin />}
            </div>
          </form>
        )}
      />
    </Section>
  )
}

class BackendConfigurationScreen extends React.Component {
  componentDidMount() {
    this.props.getContentAssetEmail()
  }

  render() {
    const { t } = this.props

    return (
      <NotificationContext.Consumer>
        {context => (
          <LoggedInScreenContainer>
            <HeaderContainer>
              <H1>{t('backend_configuration.title')}</H1>
            </HeaderContainer>
            <SubHeaderContainer>
              <Paragraph>
                {t('backend_configuration.sub_header_description')}
              </Paragraph>
            </SubHeaderContainer>
            <ContentContainerWithoutPadding>
              <EmailSection
                t={t}
                initialValues={
                  this.props.contentAssetEmailData != null
                    ? this.props.contentAssetEmailData.settings
                    : {}
                }
                onSubmit={withHandlers(
                  this.props.updateContentAssetEmail,
                  () =>
                    context.methods.toggleTopNotification(
                      'success',
                      t('backend_configuration.content_asset_update_success')
                    ),
                  () =>
                    context.methods.toggleTopNotification(
                      'error',
                      t('backend_configuration.content_asset_update_failed')
                    )
                )}
              />
            </ContentContainerWithoutPadding>
          </LoggedInScreenContainer>
        )}
      </NotificationContext.Consumer>
    )
  }
}

const BackendConfigurationScreenWrapper = props => (
  <Subscribe to={[AppSettingsContainer]}>
    {({
      getContentAssetEmail,
      updateContentAssetEmail,
      state: {
        getContentAssetEmailLoading,
        getContentAssetEmailError,
        contentAssetEmailData,
        updateContentAssetEmailLoading,
        updateContentAssetEmailError,
      },
    }) => (
      <BackendConfigurationScreen
        {...props}
        contentAssetEmailData={contentAssetEmailData}
        getContentAssetEmail={getContentAssetEmail}
        getContentAssetEmailLoading={getContentAssetEmailLoading}
        getContentAssetEmailError={getContentAssetEmailError}
        updateContentAssetEmail={updateContentAssetEmail}
        updateContentAssetEmailError={updateContentAssetEmailError}
        updateContentAssetEmailLoading={updateContentAssetEmailLoading}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(BackendConfigurationScreenWrapper)

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import common_de from './de/common.json'
import common_en from './en/common.json'

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'german',
  resources: {
    english: {
      common: common_en,
    },
    german: {
      common: common_de,
    },
  },
})

export default i18n

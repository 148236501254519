import { css } from 'styled-components'

const breakpoints = {
  largeDesktop: 1280,
  desktop: 960,
  largeTablet: 840,
  tablet: 768,
  phone: 640,
  smallPhone: 480,
}

export default Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  height: 100%;
`

const SidebarContainer = styled.div`
  height: 100%;
  width: 320px;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`

const ContentContainer = styled.div`
  height: 100%;
  flex: 1;
  overflow-y: scroll;
`

const SidebarLayout = ({ sidebarSlot, children }) => (
  <Container>
    <SidebarContainer>{sidebarSlot()}</SidebarContainer>
    <ContentContainer>{children}</ContentContainer>
  </Container>
)

SidebarLayout.propTypes = {
  sidebarSlot: PropTypes.func.isRequired,
}

/** @component */
export default SidebarLayout

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Subscribe } from 'unstated'
import { withTranslation, useTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Section from 'presentational/Section'
import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'

import PaperPlaneO from 'react-icons/lib/fa/paper-plane-o'
import Server from 'react-icons/lib/fa/server'
import User from 'react-icons/lib/md/person'
import Tags from 'react-icons/lib/fa/tags'
import Contacts from 'react-icons/lib/md/contacts'
import Blacklist from 'react-icons/lib/md/block'
import Design from 'react-icons/lib/fa/paint-brush'

import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'

import UserContainer from 'containers/UserContainer'

import { formatDate } from 'util/date'

const WebsitesIcon = styled(PaperPlaneO)`
  color: #554c47;
`

const UserIcon = styled(User)`
  color: #554c47;
`

const LabelIcon = styled(Tags)`
  color: #554c47;
`

const ContentAssetsIcon = styled(Server)`
  color: #554c47;
`

const ContactsIcon = styled(Contacts)`
  color: #554c47;
`

const BlacklistIcon = styled(Blacklist)`
  color: #554c47;
`

const TemplatesIcon = styled(Design)`
  color: #554c47;
`

const IconContainer = styled.div`
  border: 1px solid #554c47;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HistoryContainer = styled.div`
  display: flex;
  align-items: center;
`

const HistoryEntryMessage = styled.p`
  margin: 0 0 0 15px;
  color: #554c47;
`

const Line = styled.div`
  height: 10px;
  width: 1px;
  background-color: #554c47;
  margin-left: 14px;
`

const EntityName = styled.span`
  color: #1b69b6;
  font-size: 16px;
  #554c47
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledLoader = styled(Loader)`
  margin-left: 15px;
`

const CenteredLoader = styled(Loader)`
  margin-left: calc(50% - 32px);
`

const HistoryEntry = ({ action, entity, timestamp, object, last }) => {
  const { t, i18n } = useTranslation('common')

  const entityDisplayName = {
    users: t('admin_user.history_user'),
    content_assets: t('admin_user.history_content_assets'),
    websites: t('admin_user.history_websites'),
    labels: t('admin_user.history_labels'),
    contacts: t('admin_user.history_contacts'),
    blacklists: t('admin_user.history_blacklists'),
    templates: t('admin_user.history_templates'),
  }

  const getMessage = () => {
    const time = new Date(timestamp)

    switch (action) {
      case 'create':
        return (
          <HistoryEntryMessage>
            {t('admin_user.history_create')}{' '}
            <EntityName>
              {entityDisplayName[entity]} #{object}
            </EntityName>{' '}
            {t('admin_user.history_at')}{' '}
            <span>
              {formatDate(timestamp)} {time.toLocaleTimeString()}
            </span>
          </HistoryEntryMessage>
        )
      case 'delete':
        return (
          <HistoryEntryMessage>
            {t('admin_user.history_delete')}{' '}
            <EntityName>
              {entityDisplayName[entity]} #{object}
            </EntityName>{' '}
            {t('admin_user.history_at')}{' '}
            <span>
              {formatDate(timestamp)} {time.toLocaleTimeString()}
            </span>
          </HistoryEntryMessage>
        )
      case 'update':
        return (
          <HistoryEntryMessage>
            {t('admin_user.history_update')}{' '}
            <EntityName>
              {entityDisplayName[entity]} #{object}
            </EntityName>{' '}
            {t('admin_user.history_at')}{' '}
            <span>
              {formatDate(timestamp)} {time.toLocaleTimeString()}
            </span>
          </HistoryEntryMessage>
        )
    }
  }

  const renderIcon = () => {
    switch (entity) {
      case 'users':
        return <UserIcon size="15px" />
      case 'websites':
        return <WebsitesIcon size="15px" />
      case 'content_assets':
        return <ContentAssetsIcon size="15px" />
      case 'labels':
        return <LabelIcon size="15px" />
      case 'contacts':
        return <ContactsIcon size="15px" />
      case 'blacklists':
        return <BlacklistIcon size="15px" />
      case 'templates':
        return <TemplatesIcon size="15px" />
    }
  }

  return (
    <div>
      <HistoryContainer>
        <IconContainer>{renderIcon()}</IconContainer>
        {getMessage()}
      </HistoryContainer>
      {!last && <Line />}
    </div>
  )
}

class HistoryUserScreen extends React.Component {
  componentDidMount() {
    this.props.getUserHistory(this.props.match.params.id)
    this.props.getUser(this.props.match.params.id)
  }

  render() {
    const {
      getHistoryData,
      getHistoryLoading,
      getUserData,
      getUserLoading,
      t,
    } = this.props

    return (
      <LoggedInScreenContainer>
        <Section>
          <HeadingContainer>
            <H1>
              {t('admin_user.history_title', {
                firstname: getUserData ? getUserData.firstname : '',
                lastname: getUserData ? getUserData.lastname : '',
              })}
            </H1>
            {getUserLoading && <StyledLoader size="small" />}
          </HeadingContainer>
          <Paragraph>{t('admin_user.history_description')}</Paragraph>
        </Section>
        <Section>
          {getHistoryLoading && <CenteredLoader size="large" />}
          {getHistoryData &&
            !getHistoryLoading &&
            getHistoryData.map((entry, index) => (
              <HistoryEntry
                key={index}
                action={entry.action}
                entity={entry.entity}
                timestamp={entry.createdAt}
                object={entry.object}
                last={index === getHistoryData.length - 1}
              />
            ))}
        </Section>
      </LoggedInScreenContainer>
    )
  }
}

const HistoryUserScreenWrapper = props => (
  <Subscribe to={[UserContainer]}>
    {({
      getUserHistory,
      getUser,
      state: {
        getHistoryData,
        getHistoryLoading,
        getHistoryError,
        details,
        getLoading,
        getError,
      },
    }) => (
      <HistoryUserScreen
        {...props}
        getUserHistory={getUserHistory}
        getHistoryData={getHistoryData}
        getHistoryLoading={getHistoryLoading}
        getHistoryError={getHistoryError}
        getUser={getUser}
        getUserData={details}
        getUserLoading={getLoading}
        getUserError={getError}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(HistoryUserScreenWrapper)

import React from 'react'
import { Subscribe } from 'unstated'
import { withRouter } from 'react-router-dom'

import AuthContainer from 'containers/AuthContainer'

class Logout extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.logout()
    this.props.history.push('/login')
  }

  render() {
    return null
  }
}

const LogoutWrapper = props => (
  <Subscribe to={[AuthContainer]}>
    {({ logout }) => <Logout {...props} logout={logout} />}
  </Subscribe>
)

export default withRouter(LogoutWrapper)

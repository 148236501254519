import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Form } from 'react-final-form'

import Loader from 'presentational/Loader'
import FormError from 'presentational/FormError'
import PrimaryButton from 'presentational/PrimaryButton'
import DangerButton from 'presentational/DangerButton'
import FormInlineWrapper from 'presentational/FormInlineWrapper'

import NameFormSection from 'functional/NameFormSection'
import GenderFormSection from 'functional/GenderFormSection'
import EmailFormSection from 'functional/EmailFormSection'
import CompanyFormSection from 'functional/CompanyFormSection'
import PhoneFormSection from 'functional/PhoneFormSection'

import ConfirmDeleteModal from 'functional/ConfirmDeleteModal'
import { NotificationContext } from 'functional/NotificationProvider'
import { withHandlers } from 'util/form'

import { queries } from 'util'
import { makeId } from 'presentational/TemplateListItem'

const ButtonContainer = styled.div`
  display: inline-block;
  margin-right: 9px;
  margin-top: 9px;

  ${queries.desktop`
    width: 100%;
  `};
`

/**
 * Edit profile form component.
 *
 * @version 1.0
 * @author Martin Mehl
 * @author Stjepan Golemac
 */
const UserProfileForm = props => {
  const { t, i18n } = useTranslation('common')
  return (
    <Form
      initialValues={props.data}
      onSubmit={props.onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <GenderFormSection required />
          <NameFormSection required />
          <CompanyFormSection required />
          <PhoneFormSection required />
          <EmailFormSection required disabled={props.disableEmail} />
          <FormInlineWrapper>
            {submitting && <Loader />}
            {submitError && <FormError>{submitError}</FormError>}
          </FormInlineWrapper>
          <ButtonContainer>
            <PrimaryButton submit disabled={submitting}>
              {props.create
                ? t('admin_user.create_form_button')
                : t('default.save_changes')}
            </PrimaryButton>
          </ButtonContainer>
          {props.onDelete && (
            <React.Fragment>
              <ButtonContainer>
                <NotificationContext.Consumer>
                  {context => (
                    <DangerButton
                      kind="outline"
                      onClick={() => {
                        if (props.administration) {
                          context.methods.openModal(() => (
                            <ConfirmDeleteModal
                              id={props.data['@id']}
                              onClose={context.methods.closeModal}
                              user
                              onDelete={withHandlers(
                                () => props.onDelete(props.data['@id']),
                                () =>
                                  context.methods.toggleTopNotification(
                                    'success',
                                    t('profile.delete_account_success')
                                  ),
                                () =>
                                  context.methods.toggleTopNotification(
                                    'error',
                                    t('profile.delete_account_error')
                                  )
                              )}
                            />
                          ))
                        } else {
                          props.onDelete()
                        }
                      }}
                    >
                      {t('profile.delete_account')}
                    </DangerButton>
                  )}
                </NotificationContext.Consumer>
              </ButtonContainer>
              {props.deleteLoading && <Loader />}
            </React.Fragment>
          )}
        </form>
      )}
    />
  )
}

UserProfileForm.propTypes = {
  /** The submit handler */
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  deleteLoading: PropTypes.bool,
  data: PropTypes.any,
  disableEmail: PropTypes.bool,
}

/** @component */
export default UserProfileForm

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import {
  isEmpty,
  makeMinLen,
  combineValidators,
  makeMaxLen,
} from 'util/validations'

const CompanyFormSection = ({ required, dark, noInline }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <FormFieldWrapper>
      <Field
        name="company"
        component={TextInput}
        format={(value = '') => value.trim()}
        formatOnBlur
        type="text"
        label={t('default.company')}
        dark={dark}
        noInline={noInline}
        validate={
          required && combineValidators(isEmpty, makeMinLen(3), makeMaxLen(100))
        }
      />
    </FormFieldWrapper>
  )
}

CompanyFormSection.propTypes = {
  required: PropTypes.bool,
  dark: PropTypes.bool,
  noInline: PropTypes.bool,
}
CompanyFormSection.defaultProps = {
  required: false,
  dark: false,
  noInline: false,
}

/** @component */
export default CompanyFormSection

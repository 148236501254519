import React from 'react'
import styled from 'styled-components'

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: #d6d6d6;
  color: black;
  border-radius: 5px;
  transition: background ease 0.1s, border-color ease 0.1s;

  :after {
    content: '';
    opacity: 0;
    position: absolute;
    left: 7px;
    top: 2px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    transition: opacity ease 0.1s;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${Checkmark} {
    background: black;
    transition: background ease 0.1s, border-color ease 0.1s;

    :after {
      opacity: 1;
      transition: opacity ease 0.1s;
    }
  }
`
const Container = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: 20px;
  color: black;
  font-size: 1em;
  user-select: none;
  cursor: pointer;

  &:hover ${Checkmark} {
    background: black;
    transition: background ease 0.1s;
  }
`

/**
 * Simple Checkbox Component that is not a final form input.
 * @author Jan Mägdefrau
 */
const Checkbox = ({ onChange, checked }) => {
  return (
    <Container>
      <Input type="checkbox" checked={checked} onChange={onChange} />
      <Checkmark />
    </Container>
  )
}

export default Checkbox

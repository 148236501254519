import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Edit from 'react-icons/lib/fa/edit'
import Remove from 'react-icons/lib/fa/trash'

const ContextButton = styled.div`
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: ${props => (props.isOpen ? '#e8e8e8' : 'transparent')};

  cursor: pointer;

  border: none;
  box-sizing: border-box;

  :hover {
    background-color: #e8e8e8;
  }
`

const ContextButtonText = styled.p`
  font-size: 20px;
  font-weight: bold;
  vertical-align: center;

  margin-bottom: 30px;
`

const ContextButtonContainer = styled.div`
  position: relative;
`

const Submenu = styled.div`
  position: absolute;

  z-index: 9998;

  margin-top: 5px;

  border-radius: 2px;
  background-color: #e8e8e8;

  list-style: none;
  padding-left: 0;

  width: ${props => {
    if (props.small) {
      return '180px'
    }

    if (props.left) {
      return '250px'
    }

    return 'auto'
  }};
  left: ${props => {
    if (props.small) {
      return '-150px'
    }

    if (props.left) {
      return '-220px'
    }

    return 'auto'
  }};
`

const SubmenuItem = styled.div`
  background-color: #e8e8e8;
  border-radius: 2px;

  color: #0b0b0b;
  font-size: 14px;
  line-height: 40px;

  white-space: nowrap;

  padding: 2.5px 10px;
  cursor: pointer;

  :hover {
    background-color: #d7d7d7;
  }
`

const Caret = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 4px;
    border-bottom: 12px solid #e8e8e8;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;

    :hover {
      background-color: #e8e8e8;
    }
  }

  :after {
    content: '';
    position: absolute;
    left: 12px;
    top: 12px;
    border-bottom: 0px solid #fff;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
  }
`

class ContextMenuButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      context: {},
    }
  }

  closeTimeout = null

  toggleContextMenu(e) {
    e.preventDefault()
    let { isOpen } = this.state
    isOpen = isOpen ? false : true
    this.setState({ isOpen })
  }

  closeContextMenuOnLeave(e) {
    e.preventDefault()

    this.closeTimeout = setTimeout(() => this.setState({ isOpen: false }), 500)
  }

  preventClosing = e => {
    e.preventDefault()

    if (this.closeTimeout) clearTimeout(this.closeTimeout)
  }

  onItemClick = (method, id) => {
    if (this.closeTimeout) clearTimeout(this.closeTimeout)
    this.setState({ isOpen: false })

    method(id)
  }

  renderContextMenu(context, id) {
    return Object.keys(context).map(key => (
      <SubmenuItem
        key={key}
        onClick={e => this.onItemClick(context[key].method, id)}
      >
        {context[key].name}
      </SubmenuItem>
    ))
  }

  render() {
    const { isOpen } = this.state
    const { id, context } = this.props
    return (
      <ContextButtonContainer
        onMouseLeave={e => this.closeContextMenuOnLeave(e)}
        onMouseEnter={this.preventClosing}
      >
        <ContextButton
          isOpen={this.state.isOpen}
          onClick={e => this.toggleContextMenu(e)}
        >
          {this.props.contextMenuBinIcon ? <Remove /> : <Edit />}
          {/* <ContextButtonText>...</ContextButtonText> */}
        </ContextButton>
        {isOpen ? (
          <React.Fragment>
            <Caret />
            <Submenu left={this.props.left} small={this.props.small}>
              {this.renderContextMenu(context, id)}
            </Submenu>
          </React.Fragment>
        ) : null}
      </ContextButtonContainer>
    )
  }
}

ContextMenuButton.propTyped = {
  context: PropTypes.object.isRequired,
  id: PropTypes.number,
  left: PropTypes.bool,
  contextMenuBinIcon: PropTypes.bool,
}

ContextMenuButton.defaultProps = {
  left: false,
  contextMenuBinIcon: false,
}

export default ContextMenuButton

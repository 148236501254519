import React from 'react'
import styled from 'styled-components'

const AuthLogoContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 40px;
  margin-top: 40px;
`

export default AuthLogoContainer

import { darken } from 'polished'
import styled from 'styled-components'

import Button from '../Button'

const PrimaryButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.primary};
  border-color: ${props => props.theme.colors.primary};

  :hover {
    background: ${props => darken(0.1, props.theme.colors.primary)};
  }
`

/** @component */
export default PrimaryButton

import styled from 'styled-components'

/**
 * This is a H3 component.
 * @author Stjepan Golemac
 */
const H3 = styled.h3`
  font-weight: normal;
  font-size: 1.5em;
  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.normal};
`

/** @component */
export default H3

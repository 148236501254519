export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.substr(1)
}

export function isUrl(url) {
  if (!url || url === null) return
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)
  return url.match(regex)
}

import React from 'react'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import AuthLayout from 'presentational/AuthLayout'
import SetNewPasswordForm from 'functional/SetNewPasswordForm'
import Logo from 'presentational/Logo'
import H1 from 'presentational/H1'
import Paragraph from 'presentational/Paragraph'
import AuthLogoContainer from 'presentational/AuthLogoContainer'

import AccountContainer from 'containers/AccountContainer'

import { withHandlers } from 'util/form'

/**
 * A screen to set a new password after a forgot password request
 * @author Stjepan Golemac
 */
const SetNewPasswordScreen = ({ history: { push }, match: { params } }) => {
  const { t } = useTranslation('common')

  return (
    <AuthLayout>
      <AuthLogoContainer>
        <Logo width={'220px'} />
      </AuthLogoContainer>
      <H1 dark>{t('login_register.set_new_password_header')}</H1>
      <Paragraph>{t('login_register.set_new_password_description')}</Paragraph>
      <Subscribe to={[AccountContainer]}>
        {({ setNewPassword }) => (
          <SetNewPasswordForm
            onSubmit={withHandlers(
              data => setNewPassword({ ...data, ...params }),
              () => push('/login')
            )}
          />
        )}
      </Subscribe>
    </AuthLayout>
  )
}

/** @component */
export default SetNewPasswordScreen

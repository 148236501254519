import Button from '../Button'
import styled from 'styled-components'
import { lighten } from 'polished'

const DangerButton = styled(Button)`
  color: ${props => props.theme.colors.backgrounds.button.complement};
  border: 2px solid ${props => props.theme.colors.backgrounds.button.complement};
  background: transparent;

  transition: all 0.5s ease;

  :hover {
    background-color: ${props =>
      props.theme.colors.backgrounds.button.complement};
    border: 2px solid
      ${props => props.theme.colors.backgrounds.button.complement};
    color: #fff;
  }
`

/** @component */
export default DangerButton

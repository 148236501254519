import React from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { isEmail } from 'util'
import Button from 'presentational/Button'
import FormError from 'presentational/FormError'
import TextInput from 'presentational/TextInput'
import AuthFormLoader from 'presentational/AuthFormLoader'

const BottomMargin = styled.div`
  margin-bottom: 20px;
`

const ForgotPasswordForm = props => {
  const { t } = useTranslation('common')

  return (
    <Form
      onSubmit={props.onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <BottomMargin>
            <Field
              dark
              name="email"
              component={TextInput}
              format={(value = '') => value.trim()}
              formatOnBlur
              type="text"
              label={t('default.email')}
              validate={isEmail}
              noInline
            />
          </BottomMargin>
          <BottomMargin>
            <Button submit size="large" disabled={submitting}>
              {t('login_register.reset_password_button')}
            </Button>
          </BottomMargin>
          {submitting && <AuthFormLoader />}
          {submitError && <FormError>{submitError}</FormError>}
        </form>
      )}
    />
  )
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ForgotPasswordForm

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Done from 'react-icons/lib/md/done'
import Error from 'react-icons/lib/md/error'
import Close from 'react-icons/lib/md/close'

import H4 from 'presentational/H4'
import H5 from 'presentational/H5'

import { NotificationContext } from 'functional/NotificationProvider'

const backgrounds = {
  success: '#4A90E2',
  error: '#d36841',
}

const TopNotificationWrapper = styled.div`
  position: absolute;
  top: 0;

  z-index: 9999;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  height: 112px;
  width: ${({ isLoggedIn }) => (isLoggedIn ? 'calc(100% - 320px)' : '100%')};

  background-color: ${props => backgrounds[props.type]};
`

const DismissWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`
const MessageWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
`

const NotificationText = styled(H4)`
  padding-left: 16px;
  color: white;
`

const DismissText = styled(H5)`
  padding-left: 5px;
  color: white;
`

const TopNotification = ({ isLoggedIn }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <NotificationContext.Consumer>
      {context => {
        const { isOpen, message, type } = context.state.topNotification
        if (!isOpen) return
        return (
          <TopNotificationWrapper isLoggedIn={isLoggedIn} type={type}>
            <MessageWrapper>
              {type === 'success' ? (
                <Done color="white" size={24} />
              ) : (
                <Error color="white" size={24} />
              )}
              <NotificationText>{message}</NotificationText>
            </MessageWrapper>
            <DismissWrapper onClick={context.methods.toggleTopNotification}>
              <Close color="white" size={20} />
              <DismissText>{t('default.dismiss')}</DismissText>
            </DismissWrapper>
          </TopNotificationWrapper>
        )
      }}
    </NotificationContext.Consumer>
  )
}

export default TopNotification

import React from 'react'
import styled from 'styled-components'

import Loader from '../Loader'

const LoaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`

/**
 * A common loader for auth forms.
 * @author Stjepan Golemac
 */
const AuthFormLoader = ({ className }) => (
  <LoaderContainer className={className}>
    <Loader />
  </LoaderContainer>
)

/** @component */
export default AuthFormLoader

import Paragraph from '../Paragraph'
import styled from 'styled-components'

/**
 * A component for displaying main form error
 * @author Stjepan Golemac
 * */
const FormError = styled(Paragraph)`
  color: #c4944d;
`

/** @component */
export default FormError

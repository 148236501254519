import styled from 'styled-components'

/**
 * This is a H5 component.
 * @author Stjepan Golemac
 */
const H5 = styled.h5`
  font-weight: bolder;
  font-size: 0.9em;
  color: ${props => props.theme.colors.text.normal};
`

/** @component */
export default H5

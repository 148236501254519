import styled from 'styled-components'

/**
 * This is a H1 component.
 * @author Stjepan Golemac
 */
const H1 = styled.h1`
  font-weight: normal;
  font-size: 2.5em;
  color: ${props =>
    props.dark ? props.theme.colors.text.dark : props.theme.colors.text.normal};
`

/** @component */
export default H1

import React from 'react'

import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import TextInput from 'presentational/TextInput'
import FormFieldWrapper from 'presentational/FormFieldWrapper'

import {
  isEmpty,
  isEmail,
  makeMaxLen,
  combineValidators,
} from 'util/validations'

const EmailFormSection = ({ name, label, required, disabled }) => (
  <FormFieldWrapper>
    <Field
      name={name}
      component={TextInput}
      format={(value = '') => value.trim()}
      formatOnBlur
      type="email"
      label={label}
      validate={
        required && combineValidators(isEmail, isEmpty, makeMaxLen(100))
      }
      disabled={disabled}
    />
  </FormFieldWrapper>
)

EmailFormSection.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}
EmailFormSection.defaultProps = {
  name: 'email',
  label: 'E-mail',
  required: false,
  disabled: false,
}

/** @component */
export default EmailFormSection

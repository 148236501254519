import React from 'react'
import { withRouter } from 'react-router'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import Table from 'functional/Table'
import ConfirmDeleteModal from 'functional/ConfirmDeleteModal'
import { NotificationContext } from 'functional/NotificationProvider'
import ConfirmPasswordResetModal from 'functional/ConfirmPasswordResetModal'

import UserContainer from 'containers/UserContainer'

import { withHandlers } from 'util/form'
import { cleanId } from 'util/api'
import { formatDate } from 'util/date'

export default withRouter(({ history: { push } }) => {
  const { t, i18n } = useTranslation('common')

  return (
    <NotificationContext.Consumer>
      {context => (
        <Subscribe to={[UserContainer]}>
          {({
            state: { data },
            getUsers,
            getUsersCanLoadMore,
            deleteUser,
            deleteUsers,
          }) => (
            <Table
              getData={getUsers}
              data={data}
              idKey="@id"
              mapRow={({
                firstname,
                lastname,
                email,
                company,
                createdAt,
                latestLogin,
                isEnabled,
              }) => [
                firstname,
                lastname,
                email,
                company,
                `${new Date(createdAt).toLocaleDateString()} ${new Date(
                  createdAt
                ).toLocaleTimeString()}`,
                latestLogin
                  ? `${new Date(latestLogin).toLocaleDateString()} ${new Date(
                      latestLogin
                    ).toLocaleTimeString()}`
                  : t('default.never'),
                t(`default.${isEnabled ? 'enabled' : 'disabled'}`),
              ]}
              headers={[
                {
                  label: t('default.firstname'),
                  value: 'order[firstname]',
                  sortable: true,
                },
                {
                  label: t('default.lastname'),
                  value: 'order[lastname]',
                  sortable: true,
                },
                {
                  label: t('default.email'),
                  value: 'order[email]',
                  sortable: true,
                },
                {
                  label: t('default.company'),
                  value: 'order[company]',
                  sortable: true,
                },
                {
                  label: t('default.created_at'),
                  value: 'order[createdAt]',
                  sortable: true,
                },
                {
                  label: t('default.latest_login_at'),
                  value: 'order[latestLogin]',
                  sortable: true,
                },
                {
                  label: t('default.enabled'),
                  value: 'order[isEnabled]',
                  sortable: true,
                },
              ]}
              filter={'userData'}
              bulkOps={[
                {
                  label: t('default.delete_selected'),
                  handler: deleteUsers,
                  confirmModal: true,
                },
              ]}
              singleOps={[
                {
                  label: t('default.edit'),
                  handler: id => push(`/users/edit/${cleanId(id)}`),
                },
                {
                  label: t('admin_user.history'),
                  handler: id => push(`/users/history/${cleanId(id)}`),
                },
                {
                  label: t('default.delete'),
                  handler: id =>
                    context.methods.openModal(() => (
                      <ConfirmDeleteModal
                        id={id}
                        onClose={context.methods.closeModal}
                        user
                        onDelete={withHandlers(
                          () => deleteUser(id),
                          () =>
                            context.methods.toggleTopNotification(
                              'success',
                              t('admin_user.delete_success')
                            ),
                          () =>
                            context.methods.toggleTopNotification(
                              'error',
                              t('admin_user.delete_failed')
                            )
                        )}
                      />
                    )),
                },
                {
                  label: t('admin_user.reset_button'),
                  handler: (id, user) =>
                    context.methods.openModal(() => (
                      <ConfirmPasswordResetModal
                        notificationContext={context}
                        user={user}
                      />
                    )),
                },
              ]}
              canLoadMore={getUsersCanLoadMore()}
              contextMenuLeftOrientedSmall
            />
          )}
        </Subscribe>
      )}
    </NotificationContext.Consumer>
  )
})

import colors from './colors'
import formValues from './form'

const theme = {
  colors: colors,
  fontFamily: `"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;`,
  ...formValues,
}

export default theme

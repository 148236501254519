import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import TextInput from 'presentational/TextInput'

import { isEmail } from 'util/validations'

const EmailField = props => (
  <Field
    {...props}
    component={TextInput}
    type="text"
    validate={isEmail}
    format={(value = '') => value.trim()}
    formatOnBlur
  />
)

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  noInline: PropTypes.bool,
}

EmailField.defaultProps = {
  noInline: false,
}

/** @component */
export default EmailField

import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import ExpandMore from 'react-icons/lib/md/expand-more'

import Button from 'presentational/Button'

const DropdownContainer = styled(Button)`
  position: relative;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  border-radius: 5px;
  width: 200px;
  border: 1px solid ${props => props.theme.colors.backgrounds.dropdown};
  outline: none;

  color: ${props => props.theme.colors.text.normal};
  background-color: ${props => props.theme.colors.backgrounds.dropdown};

  :hover {
    background-color: ${props =>
      darken(0.3, props.theme.colors.backgrounds.dropdown)};
  }

  margin-right: 20px;
`

const DropdownMenu = styled.div`
  position: absolute;

  display: flex;
  flex-flow: column wrap;

  border-radius: 2px;

  bottom: -90px;
  left: 0;
  width: 100%;
`

const SubmenuItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  background-color: #e8e8e8;
  border-radius: 2px;

  width: 100%;

  color: #0b0b0b;
  font-size: 14px;
  line-height: 40px;

  padding: 2.5px 10px;
  cursor: pointer;

  :hover {
    background-color: ${props =>
      darken(0.3, props.theme.colors.backgrounds.dropdown)};
  }
`

/**
 * A Drop Down Menu component.
 * @version 1.0.0
 * @author Martin Mehl
 */
class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      selected: { name: 'Select...' },
    }

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.closeContextMenuOnLeave = this.closeContextMenuOnLeave.bind(this)
  }

  toggleDropdown() {
    let { isOpen } = this.state
    isOpen = isOpen === false ? true : false
    this.setState({ isOpen })
  }

  renderBulkOperationsMenu(bulkOperations) {
    const bulks = bulkOperations()
    return Object.keys(bulks).map(key => (
      <SubmenuItem
        key={key}
        onClick={e => this.toggleSelected(e, key, bulks[key])}
      >
        {bulks[key].name}
      </SubmenuItem>
    ))
  }

  toggleSelected(event, key, item) {
    let selected = this.state.selected
    selected = item
    this.setState({ selected })
  }

  closeContextMenuOnLeave(e) {
    e.preventDefault()
    let { isOpen } = this.state
    isOpen = false
    this.setState({ isOpen })
  }

  render() {
    const { isOpen } = this.state
    const { bulkOperations } = this.props
    return (
      <DropdownContainer
        onClick={this.toggleDropdown}
        onMouseLeave={this.closeContextMenuOnLeave}
      >
        {this.state.selected.name}
        <ExpandMore />
        {isOpen && bulkOperations ? (
          <DropdownMenu>
            {this.renderBulkOperationsMenu(bulkOperations)}
          </DropdownMenu>
        ) : null}
      </DropdownContainer>
    )
  }
}

export default Dropdown

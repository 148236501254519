import { Container } from 'unstated'

import { apiClient, withoutAuth } from 'util/api'

class AuthContainer extends Container {
  login = async ({ email, password }) => {
    try {
      const res = await apiClient.post(
        '/login_check',
        { email, password },
        withoutAuth()
      )

      localStorage.setItem('token', res.data.token)
      localStorage.setItem('refreshToken', res.data.refreshToken)
    } catch (e) {
      console.warn('in login', e)
      throw e
    }
  }

  logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')

    window.location = '/'
  }
}

export default AuthContainer

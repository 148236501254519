import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Paragraph from 'presentational/Paragraph'

import ChangeEmailForm from 'functional/ChangeEmailForm'

import AccountContainer from 'containers/AccountContainer'
import { withHandlers } from 'util/form'

/**
 * @description Modal to request an email change.
 * @param onClose Called when user closes modal.
 */
const ConfirmEmailChangeModal = ({ onClose, onSuccess, onFail }) => {
  const { t } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>{t('profile.change_email_modal_title')}</H3>
      <Paragraph>{t('profile.change_email_modal_description')}</Paragraph>
      <Subscribe to={[AccountContainer]}>
        {({ requestEmailChange }) => (
          <ChangeEmailForm
            onSubmit={withHandlers(requestEmailChange, onSuccess, onFail)}
            onClose={onClose}
          />
        )}
      </Subscribe>
    </React.Fragment>
  )
}

ConfirmEmailChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
}

/** @component */
export default ConfirmEmailChangeModal

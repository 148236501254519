import { forEachObjIndexed } from 'ramda'

export function replaceObjKeys(replacer, obj) {
  let tempObj = {}

  forEachObjIndexed((value, key) => {
    tempObj[replacer(key)] =
      value !== null && !Array.isArray(value) && typeof value === 'object'
        ? replaceObjKeys(replacer, value)
        : value
  }, obj)

  return tempObj
}

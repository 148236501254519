import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Logo from 'presentational/Logo'
import Loader from 'presentational/Loader'
import FormError from 'presentational/FormError'
import Paragraph from 'presentational/Paragraph'
import AuthLayout from 'presentational/AuthLayout'
import PrimaryButton from 'presentational/PrimaryButton'

import AuthLogoContainer from 'presentational/AuthLogoContainer'

import AccountContainer from 'containers/AccountContainer'

import { handleFailure } from 'util/form'

const ColoredH1 = styled(H1)`
  color: #fff;
`

class ConfirmNewEmailScreen extends React.Component {
  state = {
    loading: true,
    error: null,
  }

  componentDidMount() {
    const {
      match: { params },
      confirmEmail,
    } = this.props

    confirmEmail(params)
      .then(() => this.setState({ loading: false }))
      .catch(e =>
        this.setState({ loading: false, error: handleFailure(e, true) })
      )
  }

  render() {
    const {
      history: { push },
      t,
    } = this.props
    const { loading, error } = this.state

    return (
      <AuthLayout>
        <AuthLogoContainer>
          <Logo width={'220px'} />
        </AuthLogoContainer>

        <ColoredH1>{t('login_register.confirm_new_email_title')}</ColoredH1>
        {loading && <Loader />}
        {error && (
          <FormError>
            {t('login_register.confirm_new_email_description_failed')}
          </FormError>
        )}
        {!loading && !error && (
          <React.Fragment>
            <Paragraph>
              {t('login_register.confirm_new_email_description')}
            </Paragraph>
            <PrimaryButton
              onClick={() => {
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                push('/login')
              }}
            >
              {t('login_register.confirm_new_email_login')}
            </PrimaryButton>
          </React.Fragment>
        )}
      </AuthLayout>
    )
  }
}

ConfirmNewEmailScreen.propTypes = {
  history: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
}

const ConfirmEmailWrapper = props => (
  <Subscribe to={[AccountContainer]}>
    {({ confirmEmail }) => (
      <ConfirmNewEmailScreen {...props} confirmEmail={confirmEmail} />
    )}
  </Subscribe>
)

/** @component */
export default withTranslation('common')(ConfirmEmailWrapper)

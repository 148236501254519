import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import Sidebar from 'presentational/Sidebar'
import SidebarLayout from 'presentational/SidebarLayout'

import AddUserScreen from 'functional/AddUserScreen'
import EditUserScreen from 'functional/EditUserScreen'
import UsersListScreen from 'functional/UsersListScreen'
import EditProfileScreen from 'functional/EditProfileScreen'
import HistoryUserScreen from 'functional/HistoryUserScreen'
import NotificationProvider from 'functional/NotificationProvider'
import BackendConfigurationScreen from 'functional/BackendConfigurationScreen'

import { getTokenSync } from 'util'
import * as adminItems from './admin-navigation-targets.json'

const isLoggedIn = () => getTokenSync()

const LoggedInAdminSubroutes = props => {
  const {
    history,
    match: { url },
  } = props

  if (!isLoggedIn()) history.push('/login')

  return (
    <SidebarLayout sidebarSlot={() => <Sidebar targets={adminItems} />}>
      <NotificationProvider isLoggedIn>
        <Route
          exact
          path={`${url}settings/profile`}
          component={EditProfileScreen}
          {...props}
        />
        <Route
          exact
          path={`${url}settings/configuration`}
          component={BackendConfigurationScreen}
          {...props}
        />
        <Route
          exact
          path={`${url}users`}
          component={UsersListScreen}
          {...props}
        />
        <Route
          exact
          path={`${url}users/new`}
          component={AddUserScreen}
          {...props}
        />
        <Route
          exact
          path={`${url}users/edit/:id`}
          component={EditUserScreen}
          {...props}
        />
        <Route
          exact
          path={`${url}users/history/:id`}
          component={HistoryUserScreen}
          {...props}
        />
      </NotificationProvider>
    </SidebarLayout>
  )
}

LoggedInAdminSubroutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
}

export default LoggedInAdminSubroutes

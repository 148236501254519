import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Subscribe } from 'unstated'
import { withRouter, Redirect } from 'react-router-dom'

import Loader from 'presentational/Loader'
import Paragraph from 'presentational/Paragraph'
import NavigationSubmenuLink from 'presentational/NavigationSubmenuLink'
import Avatar from 'presentational/Avatar'

import { navigationIcon } from 'functional/NavigationList'
import KeyboardArrowRight from 'react-icons/lib/md/keyboard-arrow-right'
import KeyboardArrowUp from 'react-icons/lib/md/keyboard-arrow-up'
import logout from './icons/logout-white.svg'
import user from './icons/user-white.svg'

import AccountContainer from 'containers/AccountContainer'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;

  width: 100%;
`

const List = styled.ul`
  list-style: none;
  width: 100%;
  height: 100px;
  background-color: #685d57;
  margin: 0;
  padding-left: 0;

  z-index: 3;
`

const ListItem = styled.li`
  text-decoration: none;
  color: #f1efed;
  font-size: 16px;
  line-height: 30px;

  padding-left: 50px;
  padding-top: 20px;

  cursor: pointer;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 14px;
  }

  :hover {
    color: #fff;
  }
`

const Badge = styled.div`
  height: auto;
  width: 100%;
  background-color: #685d57;
  cursor: pointer;
  padding: 20px 0;
`

const BadgeRowContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  height: 100%;
`

const BadgeColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`

const BadgeCompany = styled(Paragraph)`
  margin: 0;
  margin-left: 48px;

  color: #f1efed;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 14px;
`

const BadgeUsername = styled(Paragraph)`
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  max-width: 200px;
  word-break: break-word;
`

const CaretRight = styled(KeyboardArrowRight)`
  color: #fff;
  font-size: 24px;
`

const CaretUp = styled(KeyboardArrowUp)`
  color: #fff;
  font-size: 24px;
`

class SidebarProfileBadge extends React.Component {
  state = {
    dropdownOpen: false,
  }

  componentDidMount() {
    this.props.getProfileData()
  }

  toggleSubmenu(e) {
    e.preventDefault()
    let { dropdownOpen } = this.state
    dropdownOpen = this.state.dropdownOpen ? false : true
    this.setState({ dropdownOpen })
  }

  render() {
    const { t } = this.props

    return (
      <Container>
        {this.state.dropdownOpen ? (
          <List>
            <ListItem>
              {navigationIcon(user)}
              <NavigationSubmenuLink to="/settings/profile">
                {t('navigation.sub_profile')}
              </NavigationSubmenuLink>
            </ListItem>
            <ListItem onClick={() => this.props.history.push('/logout')}>
              {navigationIcon(logout)}
              {t('navigation.sub_logout')}
            </ListItem>
          </List>
        ) : null}
        <Badge onClick={e => this.toggleSubmenu(e)}>
          <BadgeRowContainer>
            <BadgeColumnContainer>
              <Subscribe to={[AccountContainer]}>
                {({ state: { profileLoading, profileError, profileData } }) => {
                  if (profileLoading) return <Loader />
                  if (profileError) return profileError
                  if (!profileData) return null

                  if (!profileData.registered) {
                    return <Redirect to="/onboarding-wizard" />
                  }

                  const { firstname, lastname, company } = profileData

                  const avatar = profileData.avatar
                    ? `data:${profileData.avatar.mimetype};base64,${profileData.avatar.base64}`
                    : `https://ui-avatars.com/api/?name=${firstname}+${lastname}`
                  return (
                    <React.Fragment>
                      <BadgeRowContainer>
                        <Avatar src={avatar} />
                        <BadgeUsername>{`${firstname} ${lastname}`}</BadgeUsername>
                      </BadgeRowContainer>
                      <BadgeCompany>{`${company}`}</BadgeCompany>
                    </React.Fragment>
                  )
                }}
              </Subscribe>
            </BadgeColumnContainer>
            {this.state.dropdownOpen ? <CaretUp /> : <CaretRight />}
          </BadgeRowContainer>
        </Badge>
      </Container>
    )
  }
}

SidebarProfileBadge.propTypes = {
  getProfileData: PropTypes.func.isRequired,
}

const SidebarProfileBadgeWrapper = props => (
  <Subscribe to={[AccountContainer]}>
    {({ getProfileData }) => (
      <SidebarProfileBadge
        history={props.history}
        getProfileData={getProfileData}
        {...props}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(withRouter(SidebarProfileBadgeWrapper))

import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

class UserContainer extends Container {
  state = {
    data: null,
    details: null,
    metaData: null,

    createLoading: false,
    createError: null,

    getLoading: false,
    getError: null,

    updateLoading: false,
    updateError: null,

    toggleLoading: false,
    toggleError: null,

    deleteLoading: false,
    deleteError: null,

    deleteUsersLoading: false,
    deleteUsersError: null,

    getHistoryData: null,
    getHistoryLoading: false,
    getHistoryError: null,
  }

  createUser = async data => {
    try {
      await this.setState({ createLoading: true, createError: null })

      await apiClient.post('users', { ...data })

      await this.setState({ createLoading: false })
    } catch (e) {
      console.warn('in createUser', e)

      await this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  createUserByAdmin = async data => {
    try {
      await this.setState({ createLoading: true, createError: null })

      await apiClient.post('users', {
        ...data,
        plainPassword: 'qQ93uD1XCXVB',
        plainPasswordRepeated: 'qQ93uD1XCXVB',
        isEnabled: true,
      })

      await this.setState({ createLoading: false })
    } catch (e) {
      console.warn('in createUserByAdmin', e)

      await this.setState({
        createLoading: false,
        createError: handleFailure(e, true),
      })

      throw e
    }
  }

  getUsers = async (params, pageChanged) => {
    try {
      await this.setState({
        getLoading: true,
        getError: null,
      })

      let page

      if (pageChanged) {
        if (!this.state.metaData || !this.state.metaData['hydra:view']) {
          await this.setState({ loading: false })
          return
        }

        const { 'hydra:next': nextPage } = this.state.metaData['hydra:view']

        if (nextPage) page = nextPage.split('page=')[1]
        else {
          await this.setState({ loading: false })
          return
        }
      }

      const res = await apiClient.get(
        `users?${qs.stringify({ ...params, page }, { encode: false })}`
      )
      const data = res.data['hydra:member']

      await this.setState({
        getLoading: false,
        data: pageChanged ? [...this.state.data, ...data] : data,
        metaData: res.data,
      })
    } catch (e) {
      console.warn('in getUsers', e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  getUser = async (id, removeLast = true) => {
    try {
      await this.setState({
        details: removeLast ? null : undefined,
        getLoading: true,
        getError: null,
      })

      const res = await apiClient.get(`users/${cleanId(id)}`)

      await this.setState({ getLoading: false, details: res.data })
    } catch (e) {
      console.warn('in getUser', e)

      await this.setState({
        getLoading: false,
        getError: handleFailure(e, true),
      })

      throw e
    }
  }

  getUserHistory = async id => {
    try {
      await this.setState({
        getHistoryData: null,
        getHistoryLoading: true,
        getHistoryError: null,
      })

      const res = await apiClient.get(`users/${id}/history`)

      await this.setState({
        getHistoryLoading: false,
        getHistoryData: res.data,
      })
    } catch (e) {
      console.warn('in getUserHistory', e)

      await this.setState({
        getHistoryLoading: false,
        getHistoryError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateUser = async data => {
    try {
      await this.setState({ updateLoading: true, updateError: null })

      await apiClient.put(`users/${cleanId(data['@id'])}`, { ...data })

      await this.setState({ updateLoading: false })
    } catch (e) {
      console.warn('in updateUser', e)

      await this.setState({
        updateLoading: false,
        updateError: handleFailure(e, true),
      })

      throw e
    }
  }

  getUsersCanLoadMore = () => {
    if (!this.state.metaData || !this.state.metaData['hydra:view']) return false

    return !!this.state.metaData['hydra:view']['hydra:next']
  }

  toggleUser = async data => {
    try {
      await this.setState({ toggleLoading: true, toggleError: null })

      await this.updateUser(data)

      await this.setState({ toggleLoading: false })
    } catch (e) {
      console.warn('in toggleUser', e)

      await this.setState({
        toggleLoading: true,
        toggleError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteUser = async id => {
    try {
      await this.setState({ deleteLoading: true, deleteError: null })

      await apiClient.delete(`users/${cleanId(id)}`)

      this.getUsers()

      await this.setState({ deleteLoading: false })
    } catch (e) {
      console.warn('in createUser', e)

      await this.setState({
        deleteLoading: false,
        deleteError: handleFailure(e, true),
      })

      throw e
    }
  }

  deleteUsers = async ids => {
    try {
      await this.setState({
        deleteUsersLoading: true,
        deleteUsersError: null,
      })

      const query = qs.stringify(
        { ids: ids.map(id => cleanId(id)) },
        { encode: false }
      )

      await apiClient.delete(`users/bulk_delete?${query}`)

      await this.setState({ deleteUsersLoading: false })
    } catch (e) {
      console.warn('in deleteUsersContact', e)

      this.setState({
        deleteUsersLoading: false,
        deleteUsersError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default UserContainer

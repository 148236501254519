import styled from 'styled-components'

/**
 * A wrapper for common screens when the user is logged in.
 * @author Stjepan Golemac
 */
const Section = styled.div`
  padding: 48px;
  margin-bottom: 20px;
  background: white;
`

/** @component */
export default Section

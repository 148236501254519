import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'

import H1 from 'presentational/H1'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'
import LoggedInScreenContainer from 'presentational/LoggedInScreenContainer'
import {
  HeaderContainer,
  ContentContainer,
  HeaderButtonContainer,
  SubHeaderContainer,
} from 'presentational/ScreenLayoutContainers'

import List from 'functional/List'
import UsersTable from 'functional/UsersTable'

import UserContainer from 'containers/UserContainer'

class UsersList extends React.Component {
  componentDidMount() {
    this.props.getUsers()
  }

  render() {
    const { t } = this.props

    return (
      <LoggedInScreenContainer>
        <HeaderContainer>
          <H1>{t('admin_user.title')}</H1>
          <HeaderButtonContainer>
            <Button bold onClick={e => this.props.history.push('/users/new')}>
              + {t('admin_user.create_button')}
            </Button>
          </HeaderButtonContainer>
        </HeaderContainer>
        <SubHeaderContainer>
          <Paragraph>{t('admin_user.sub_header_description')}</Paragraph>
        </SubHeaderContainer>
        <ContentContainer>
          <UsersTable />
        </ContentContainer>
      </LoggedInScreenContainer>
    )
  }
}

UsersList.propTypes = {
  getUsers: PropTypes.func.isRequired,
}

const UsersListWrapper = props => (
  <Subscribe to={[UserContainer]}>
    {({ getUsers }) => <UsersList {...props} getUsers={getUsers} />}
  </Subscribe>
)

export default withTranslation('common')(UsersListWrapper)

import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;

  margin-top: 20px;
`

const ButtonLeft = styled(Button)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

class CropFileUploadModal extends Component {
  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return
    }

    this.props.onSave(this.cropper.getCroppedCanvas().toDataURL())
  }

  render() {
    const { file, onClose, ratio, t } = this.props

    return (
      <React.Fragment>
        <H3>{t('default.crop_image')}</H3>
        <Cropper
          ref={cropper => (this.cropper = cropper)}
          src={file}
          style={{ height: 400, width: '100%' }}
          // Cropper.js options
          aspectRatio={ratio}
          guides={false}
          zoomable
          autoCrop
          autoCropArea={1}
          highlight={true}
          movable={false}
        />
        <ButtonWrapper>
          <ButtonLeft onClick={() => this.cropImage()}>
            {t('default.confirm')}
          </ButtonLeft>
          <ButtonRight onClick={onClose} type="nobg">
            {t('default.cancel')}
          </ButtonRight>
        </ButtonWrapper>
      </React.Fragment>
    )
  }
}

CropFileUploadModal.propTypes = {
  file: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  ratio: PropTypes.number,
}

CropFileUploadModal.defaultProps = {
  onClose: () => {},
  ratio: 1 / 1,
}

export default withTranslation('common')(CropFileUploadModal)

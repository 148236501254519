import zxcvbnAsync from 'zxcvbn-async'
import createDecorator from 'final-form-calculate'

const passLimit = 64

export function makePasswordStrengthField(
  passwordField = 'plainPassword',
  strengthField = 'passwordStrength'
) {
  return createDecorator({
    field: passwordField,
    updates: {
      [strengthField]: password =>
        zxcvbnAsync
          .load({})
          .then(zxcvbn => {
            const estimation = zxcvbn(
              password.length > passLimit
                ? password.slice(0, passLimit)
                : password
            )
            const entropy = Math.log2(estimation.guesses)

            if (!password) {
              return 0
            } else if (entropy >= 30) {
              return 4
            } else if (entropy >= 25) {
              return 3
            } else if (entropy >= 20) {
              return 2
            } else if (entropy < 20) {
              return 1
            }
          })
          .catch(e => {
            console.error()
            return 0
          }),
    },
  })
}

import styled from 'styled-components'

import queries from 'util/mediaQueries'

/**
 * A component that aligns the form contents with fields.
 * @author Stjepan Golemac
 */
const FormInlineWrapper = styled.div`
  margin-left: ${props => props.theme.formInlineTotalOffset};

  ${queries.desktop`
    margin-left: 0px;
  `};
`

/** @component */
export default FormInlineWrapper

import styled from 'styled-components'

/**
 * This is a H2 component.
 * @author Stjepan Golemac
 */
const H2 = styled.h2`
  font-weight: normal;
  font-size: 2em;
  color: ${props => props.theme.colors.text.normal};
`

/** @component */
export default H2

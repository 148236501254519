import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Subscribe } from 'unstated'
import { withTranslation } from 'react-i18next'

import PdfIcon from 'react-icons/lib/fa/file-pdf-o'
import ImageIcon from 'react-icons/lib/fa/file-image-o'
import Plus from 'react-icons/lib/fa/plus'
import Delete from 'react-icons/lib/fa/trash-o'

import Loader from 'presentational/Loader'
import Button from 'presentational/Button'

import { getFileType } from 'util/file'
import FileContainer from 'containers/FileContainer'

import { NotificationContext } from 'functional/NotificationProvider'
import CropFileUploadModal from 'functional/CropFileUploadModal'

const FileInputWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  max-width: 128px;

  padding: 20px 0;

  position: relative;
`

const Placeholder = styled.label`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  height: 128px;
  width: 128px;

  margin-bottom: 10px;

  color: white;
  font-size: 32px;

  border-radius: 100%;
  cursor: pointer;

  background-color: #c0c0c0;

  ${({ thumbnail }) =>
    thumbnail !== null
      ? `
    background-image: url(${thumbnail});
    background-size: cover;
    background-position: center;
    background-repeat: norepeat;
  `
      : null}

  :hover {
    background-color: ${lighten(0.1, '#c0c0c0')};

    svg {
      color: #a0b6cb;
    }
  }
`

const FormLabel = styled.label`
  display: block;
  font-size: 1em;
  margin-bottom: 10px;
  color: ${props =>
    props.light
      ? props.theme.colors.text.light
      : props.theme.colors.text.label};
`

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  + ${Placeholder} * {
    pointer-events: none;
  }

  + ${Button} {
    svg {
      margin-right: 5px;
    }
  }
`

class FileInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theInputKey: null,
    }
  }
  setFile = (file, context, crop = false) => {
    if (file === null) return

    const { uploadFile, onUpload, onUploadFailed } = this.props

    const fileReader = new FileReader()

    fileReader.onload = loadEvent => {
      const binaryData = loadEvent.target.result

      if (crop.active) {
        context.methods.openModal(() => (
          <CropFileUploadModal
            file={binaryData}
            ratio={crop.ratio}
            onSave={image => {
              const base64 = image.split(',')[1]

              uploadFile(base64)
                .then(fileRes => onUpload(fileRes.name, image))
                .catch(onUploadFailed)

              this.setState({
                thumbnail: image,
                uploaded: true,
              })

              context.methods.closeModal()
            }}
            onClose={() => context.methods.closeModal()}
          />
        ))
      } else {
        const base64 = binaryData.split(',')[1]

        uploadFile(base64)
          .then(fileRes => onUpload(fileRes.name, loadEvent.target.result))
          .catch(onUploadFailed)

        this.setState({ thumbnail: loadEvent.target.result, uploaded: true })
      }
    }

    fileReader.readAsDataURL(file)
  }

  reesetsTheFileInput() {
    let randomString = Math.random().toString(36)

    this.setState({
      theInputKey: randomString,
    })
  }

  render() {
    const typeIcons = {
      jpg: <ImageIcon />,
      jpeg: <ImageIcon />,
      png: <ImageIcon />,
      pdf: <PdfIcon />,
    }
    const {
      uploadFileLoading,
      accept,
      label,
      loading,
      fileName,
      file,
      onDelete,
      t,
      crop,
    } = this.props
    const type = getFileType(fileName)

    return (
      <NotificationContext.Consumer>
        {context => (
          <FileInputWrapper>
            <FormLabel light={this.props.light}>{label}</FormLabel>
            <Placeholder htmlFor="file" thumbnail={file}>
              {uploadFileLoading || loading ? <Loader /> : ''}
              {file && !uploadFileLoading && !loading ? typeIcons[type] : ''}
              {!file && !uploadFileLoading && !loading ? <Plus /> : ''}
            </Placeholder>
            <Input
              accept={accept}
              onChange={e => {
                this.reesetsTheFileInput()
                this.setFile(e.currentTarget.files[0], context, crop)
              }}
              id="file"
              name="file"
              type="file"
              className="inputfile"
              key={this.state.theInputKey || ''}
            />
            {file && (
              <Button
                kind="dark"
                onClick={e => {
                  this.reesetsTheFileInput()
                  onDelete(e)
                }}
                disabled={!file}
              >
                <Delete />
                {t('default.delete')}
              </Button>
            )}
          </FileInputWrapper>
        )}
      </NotificationContext.Consumer>
    )
  }
}

FileInput.propTypes = {
  uploadFileLoading: PropTypes.bool,
  accept: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  file: PropTypes.string,
  onDelete: PropTypes.func,
  onUploadFailed: PropTypes.func,
  crop: PropTypes.shape({
    active: PropTypes.bool,
    ratio: PropTypes.number,
  }),
}

FileInput.defaultProps = {
  accept: 'image/png, image/jpeg, .pdf',
  crop: {
    active: false,
    ration: 1 / 1,
  },
}

const FileInputContainer = props => (
  <Subscribe to={[FileContainer]}>
    {({
      state: {
        uploadFileLoading,
        uploadFileError,
        getFileLoading,
        getFileError,
      },
      uploadFile,
      getFile,
    }) => (
      <FileInput
        {...props}
        uploadFile={uploadFile}
        uploadFileLoading={uploadFileLoading}
        uploadFileError={uploadFileError}
        getFile={getFile}
      />
    )}
  </Subscribe>
)

export default withTranslation('common')(FileInputContainer)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ArrowBack from 'react-icons/lib/md/arrow-back'

const Button = styled.button`
  color: ${props =>
    props.dark ? props.theme.colors.text.normal : props.theme.colors.text.dark};
  padding: 10px 0px;
  border: none;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;

  outline: none;

  background-color: ${props =>
    props.dark
      ? props.theme.colors.backgrounds.normal
      : props.theme.colors.backgrounds.dark};
`

const Icon = styled(ArrowBack)`
  padding: 0px 3px 3px 0px;
`

const TextContainer = styled.span`
  padding-right: 5px;
`

/**
 * A button to return you back to previous page.
 *
 * @version 1.0.0
 * @author Stjepan Golemac
 */
const BackButton = ({ history: { goBack } }) => {
  const { t } = useTranslation('common')
  return (
    <Button onClick={goBack}>
      <Icon size={24} />
      <TextContainer>{t('default.go_back')}</TextContainer>
    </Button>
  )
}

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
}

/** @component */
export default withRouter(BackButton)

import { Container } from 'unstated'
import qs from 'qs'

import { apiClient, cleanId } from 'util/api'
import { handleFailure } from 'util/form'

export default class ApiContainer extends Container {
  state = {
    contentAssetEmailData: null,
    mailServerDomain: null,

    getContentAssetEmailLoading: false,
    getContentAssetEmailError: null,

    updateContentAssetEmailLoading: false,
    updateContentAssetEmailError: null,

    getMailServerDomainLoading: false,
    getMailServerDomainError: null,
  }

  getMailServerDomain = async () => {
    try {
      await this.setState({
        getMailServerDomainLoading: true,
        getMailServerDomainError: null,
      })

      const res = await apiClient.get('websites/mail_server')

      await this.setState({
        getMailServerDomainLoading: false,
        mailServerDomain: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getMailServerDomainLoading: false,
        getMailServerDomainError: handleFailure(e, true),
      })

      throw e
    }
  }

  getContentAssetEmail = async () => {
    try {
      await this.setState({
        getContentAssetEmailLoading: true,
        getContentAssetEmailError: null,
      })

      const res = await apiClient.get('app_settings/2')

      await this.setState({
        getContentAssetEmailLoading: false,
        contentAssetEmailData: res.data,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        getContentAssetEmailLoading: false,
        getContentAssetEmailError: handleFailure(e, true),
      })

      throw e
    }
  }

  updateContentAssetEmail = async data => {
    try {
      await this.setState({
        updateContentAssetEmailLoading: true,
        updateContentAssetEmailError: null,
      })

      await apiClient.put(`app_settings/2`, {
        settings: data,
        key: 'content_asset_email',
      })

      await this.setState({
        updateContentAssetEmailLoading: false,
      })
    } catch (e) {
      console.warn(e)

      await this.setState({
        updateContentAssetEmailLoading: false,
        updateContentAssetEmailError: handleFailure(e, true),
      })

      throw e
    }
  }
}

import { FORM_ERROR } from 'final-form'
import i18n from '../translation/i18n'

function mapViolationsToErrors(violations) {
  return violations.reduce(
    (acc, curr) => ({ ...acc, [curr.propertyPath]: curr.message }),
    {}
  )
}

export function getErrorMessage(error) {
  if (error !== undefined && error !== null) {
    if (error === 'User with email address already exist') {
      return i18n.getFixedT(null, 'common')('login_register.email_exists')
    }
    if (error === 'This value is not a valid URL.') {
      return i18n.getFixedT(null, 'common')('form.not_url')
    }
    if (error.includes('Password needs to be')) {
      return i18n.getFixedT(null, 'common')('login_register.password_to_weak')
    }
    if (error === 'Token is not valid') {
      return i18n.getFixedT(null, 'common')('login_register.token_invalid')
    }
    if (error.includes('Email address already exists in blacklist.')) {
      return i18n.getFixedT(null, 'common')(
        'contacts.blacklisted_already_in_list'
      )
    }
    if (error.includes('Password is not valid.')) {
      return i18n.getFixedT(null, 'common')('login_register.bad_credentials')
    }
  }
  return error
}

export function handleFailure(e, onlyMessage = false) {
  if (e.response === undefined) {
    return { [FORM_ERROR]: 'Unexpected error' }
  }
  let message = e.response.data.message
  let violations = null

  if (
    typeof e.response.data === 'string' &&
    e.response.data.includes('DOCTYPE')
  ) {
    message = 'Unexpected error'
  }

  if (e.response.data.violations) {
    violations = mapViolationsToErrors(e.response.data.violations)
  }

  if (e.response.data['hydra:description']) {
    message = e.response.data['hydra:description']
  } else if (e.response.data['hydra:title']) {
    message = e.response.data['hydra:title']
  }

  if (onlyMessage) {
    return message || 'Unexpected error'
  }

  const errors = { [FORM_ERROR]: message, ...(violations || {}) }

  return errors
}

export function withHandlers(onSubmit, thenHandler, catchHandler = () => null) {
  return values => {
    let p = onSubmit(values).then(() => undefined)

    if (Array.isArray(thenHandler))
      thenHandler.forEach(handler => {
        p = p.then(handler)
      })
    else if (thenHandler) {
      p = p.then(thenHandler)
    }

    p = p.catch(e => {
      const errors = handleFailure(e)
      catchHandler(errors)
      return errors
    })

    return p
  }
}

function handleResolverFailure(e, form = true) {
  if (
    e.networkError &&
    e.networkError.response &&
    e.networkError.response.data
  ) {
    const { data } = e.networkError.response

    if (data.violations) {
      return mapViolationsToErrors(e.networkError.response.data.violations)
    }

    if (data.message) {
      return form ? { [FORM_ERROR]: data.message } : data.message
    }
  }

  if (e.networkError && typeof e.networkError === 'string') {
    if (e.networkError.includes('.') || e.networkError.includes(':')) {
      return mapGraphErrors(e.networkError)
    }

    return form ? { [FORM_ERROR]: e.networkError } : e.networkError
  }

  return form ? { [FORM_ERROR]: e.message } : e.message
}

export function makeOnSubmit(onSubmit, form = true) {
  return values => {
    const promise = onSubmit(values)

    if (!promise)
      throw new Error('makeOnSubmit: Passed function must return promise')

    /**
     * For the final-form, returning undefined means there are no errors and
     * the submission is successful.
     */
    return promise
      .then(() => undefined)
      .catch(e => handleResolverFailure(e, form))
  }
}

export function shouldRenderError(meta) {
  if (!meta) {
    return false
  }

  const { touched, error, submitError, dirtySinceLastSubmit } = meta

  if (dirtySinceLastSubmit) {
    return !!error
  }

  if (touched === undefined) {
    return !!error || !!submitError
  }

  return touched && (!!error || !!submitError)
}

export function normalizePhone(value) {
  if (!value) return value

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) return onlyNums

  if (onlyNums.length <= 7)
    return `+${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 7)}`

  return `+${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )} ${onlyNums.slice(10)}`
}

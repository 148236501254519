import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ArrowDown from 'react-icons/lib/md/keyboard-arrow-down'

import { shouldRenderError } from 'util'
import InputError from '../InputError'

import queries from 'util/mediaQueries'

const ContainerInlineStyles = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${queries.desktop`
    display: block;
  `};
`

const ContainerSubSelectStyles = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
`

const ContainerNoInlineStyles = css`
  display: block;
`

const Container = styled.div`
  ${props => !props.noInline && ContainerInlineStyles};
  ${props => !props.subSelect && ContainerSubSelectStyles};
  ${props => props.noInline && ContainerNoInlineStyles};
`

const LabelInlineStyles = css`
  margin-bottom: 0px;
  margin-right: ${props => props.theme.formInlineLabelMargin};
  text-align: right;
  flex-basis: ${props => props.theme.formInlineOffset};

  ${queries.desktop`
    margin-bottom: 10px;
    text-align: left;
  `};
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 1em;
  ${props => !props.noInline && LabelInlineStyles};
  color: ${props =>
    props.dark ? props.theme.colors.text.light : props.theme.colors.text.label};
`

const InnerContainer = styled.div`
  flex: 1;
  position: relative;
  cursor: pointer;
  border-bottom: ${props =>
    props.dark && props.noInline ? '2px solid white' : ''};
  border-radius: 2px;
`

const Select = styled.select`
  font-size: 13px;
  min-width: 100%;
  padding: 1em 1.8em 1em 0.5em;
  background: none;
  appearance: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: ${props => {
    if (props.dark) {
      return 'white'
    }

    if (props.color) {
      return props.color
    }

    return 'none'
  }};
`

const ArrowDownIcon = styled(ArrowDown)`
  position: absolute;
  top: 22px;
  right: 0;
  margin-top: -12px;
  pointer-events: none;
  color: ${props =>
    props.dark ? props.theme.colors.text.light : props.theme.colors.text.label};
`

const Option = styled.option`
  background-color: ${props => (props.dark ? '#554c47' : '')};
`

/**
 * A select input component compliant with final-form props.
 */
const SelectInput = ({
  noInline,
  label,
  dark,
  meta,
  input,
  options,
  onChange,
}) => {
  return (
    <Container noInline={noInline}>
      {label && (
        <Label noInline={noInline} dark={dark}>
          {label}
        </Label>
      )}
      <InnerContainer
        noInline={noInline}
        dark={dark}
        style={{
          borderBottomColor: shouldRenderError(meta) && '#c4944d',
          borderBottomWidth: shouldRenderError(meta) && '2px',
        }}
      >
        <Select
          {...input}
          dark={dark}
          onChange={event =>
            onChange ? onChange(event.target.value) : input.onChange(event)
          }
        >
          {options.map(({ label, value }) => (
            <Option key={`${label}-${value}`} value={value} dark={dark}>
              {label}
            </Option>
          ))}
        </Select>
        <ArrowDownIcon size={24} />
      </InnerContainer>
      {shouldRenderError(meta) && (
        <InputError>{meta.error || meta.submitError}</InputError>
      )}
    </Container>
  )
}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  label: PropTypes.string,
  noInline: PropTypes.bool,
  subSelect: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    color: PropTypes.string,
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    data: PropTypes.object,
    dirty: PropTypes.bool,
    error: PropTypes.any,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitError: PropTypes.any,
    submitFailed: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
  }),
  onChange: PropTypes.func,
}

SelectInput.defaultProps = {
  options: [],
  noInline: false,
}

/** @component */
export default SelectInput

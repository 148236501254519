import React from 'react'
import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding: 0 48px;
  padding-top: 48px;
  padding-bottom: 0;
  background: ${props => props.theme.colors.backgrounds.normal};
`

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 48px;
  padding-bottom: 28px;

  background-color: ${props => props.theme.colors.backgrounds.normal};

  button {
    max-width: 25%;
    margin-left: 18rem;
  }
`

export const ContentContainer = styled.div`
  background-color: ${props => props.theme.colors.backgrounds.normal};
  padding: 0 48px;
  padding-bottom: 24px;
  margin-top: 24px;
`

export const TabbedContentContainer = styled(ContentContainer)`
  margin-top: 0;
  padding-top: 0;
  background: #f1efed;
`

export const HeaderButtonContainer = styled.div`
  display: flex;
  margin-left: 20px;

  * {
    margin-right: 15px;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;

  background: ${props => props.theme.colors.backgrounds.normal};

  padding: 48px;

  margin-top: 20px;

  button {
    max-width: 25%;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

import React from 'react'
import PropTypes from 'prop-types'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'

import AccountContainer from 'containers/AccountContainer'

import { withHandlers } from 'util/form'

const ButtonContainer = styled.div`
  display: flex;
`
const ButtonLeft = styled(Button)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

const RedParagraph = styled(Paragraph)`
  color: red;
`

const ConfirmPasswordResetModal = ({ user, notificationContext }) => {
  const { t, i18n } = useTranslation('common')
  return (
    <React.Fragment>
      <H3>
        {t('admin_user.reset_modal_header', {
          firstname: user.firstname,
          lastname: user.lastname,
        })}
      </H3>
      {user.isEnabled && (
        <Paragraph>{t('admin_user.reset_modal_description')}</Paragraph>
      )}
      {!user.isEnabled && (
        <RedParagraph>{t('admin_user.reset_modal_blocked')}</RedParagraph>
      )}
      <Subscribe to={[AccountContainer]}>
        {({
          state: { requestNewPasswordLoading, requestNewPasswordError },
          requestNewPassword,
        }) => (
          <ButtonContainer>
            <ButtonLeft
              disabled={!user.isEnabled}
              onClick={withHandlers(
                () => requestNewPassword(user),
                () => {
                  notificationContext.methods.toggleTopNotification(
                    'success',
                    t('admin_user.reset_success', {
                      firstname: user.firstname,
                      lastname: user.lastname,
                    })
                  )
                  notificationContext.methods.closeModal()
                },
                () => {
                  notificationContext.methods.toggleTopNotification(
                    'error',
                    t('admin_user.reset_failed', {
                      firstname: user.firstname,
                      lastname: user.lastname,
                    })
                  )
                }
              )}
            >
              {t('admin_user.reset_button')}
            </ButtonLeft>
            <ButtonRight
              onClick={() => notificationContext.methods.closeModal()}
            >
              {t('default.cancel')}
            </ButtonRight>
          </ButtonContainer>
        )}
      </Subscribe>
    </React.Fragment>
  )
}

ConfirmPasswordResetModal.propTypes = {
  notificationContext: PropTypes.object.isRequired,
}

/** @component */
export default ConfirmPasswordResetModal

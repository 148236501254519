import React from 'react'
import styled from 'styled-components'

const Avatar = styled.img`
  width: 26px;
  height: 26px;

  border-radius: 100%;

  margin: 0 10px;
`

export default Avatar

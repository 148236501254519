import axios from 'axios'

const URL =
  window.config !== undefined && window.config.API_URL
    ? window.config.API_URL
    : process.env.API_URL

const tokenKey = 'token'
const refreshTokenKey = 'refreshToken'
const maxMs = 10000

let isRefreshing = false

/**
 * Returns a promise that resolves with a token or fails with an error.
 */
async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem(refreshTokenKey)

    if (!refreshToken) {
      throw new Error('RefreshToken: no refresh token available')
    }

    isRefreshing = true
    const res = await axios.post(`${URL}/v1/token/refresh`, { refreshToken })

    if (!res.data || !res.data.token) {
      throw new Error('RefreshToken: refresh token request failed', res)
    }

    setTokens(res.data)
    isRefreshing = false

    return res.data.token
  } catch (error) {
    isRefreshing = false

    if (error.response && error.response.status === 400) {
      console.log('Refresh token failed. Redirect to logout.')
      window.location = '/logout'
      return
    }

    throw error
  }
}

/**
 * Waits for the resolvement of token refresh
 */
function forResolve() {
  let waitedMs = 0

  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      waitedMs += 10

      if (waitedMs >= maxMs) {
        clearInterval(timer)

        reject('There was a problem with token refresh')
        return
      }

      if (!isRefreshing) {
        clearInterval(timer)

        resolve('Do it now!')
        return
      }
    }, 10)
  })
}

export function getTokenSync() {
  return localStorage.getItem(tokenKey)
}

export async function getToken() {
  if (!isRefreshing) {
    return localStorage.getItem(tokenKey)
  }

  await forResolve()
  return localStorage.getItem(tokenKey)
}

export async function refreshIt() {
  if (!isRefreshing) {
    return refreshToken()
  }

  await forResolve()
  return localStorage.getItem(tokenKey)
}

export function setTokens({ token, refreshToken } = {}) {
  if (!token || !refreshToken) {
    throw new Error('Tokens: one or both tokens do not exist')
  }

  localStorage.setItem(tokenKey, token)
  localStorage.setItem(refreshTokenKey, refreshToken)
}

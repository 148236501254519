import { Container } from 'unstated'

import { apiClient } from 'util/api'
import { handleFailure } from 'util/form'

class FileContainer extends Container {
  state = {
    uploadFileLoading: false,
    uploadFileError: null,
    uploadFileData: null,

    getFileLoading: false,
    getFileError: null,
    getFileData: null,
  }

  uploadFile = async file => {
    try {
      await this.setState({ uploadFileLoading: true, uploadFileError: null })

      const res = await apiClient.post('upload', { file })

      await this.setState({ uploadFileLoading: false, uploadFileData: res })

      return res.data
    } catch (e) {
      console.warn('in uploadFile', e)

      await this.setState({
        uploadFileLoading: false,
        uploadFileError: e,
      })

      throw e
    }
  }

  getFile = async fileName => {
    try {
      await this.setState({ getFileLoading: true, getFileError: null })

      const res = await apiClient.get(`file/${fileName}`)

      await this.setState({ getFileLoading: false, getFileData: res })

      return res.data
    } catch (e) {
      console.warn('in getFile', e)

      await this.setState({
        getFileLoading: false,
        getFileError: handleFailure(e, true),
      })

      throw e
    }
  }
}

export default FileContainer

import i18n from '../translation/i18n'

export function combineValidators(...validators) {
  return (...params) =>
    validators.reduce(
      (error, validator) => error || validator(...params),
      undefined
    )
}

export function isEmail(value) {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

  return regex.test(value)
    ? undefined
    : i18n.getFixedT(null, 'common')('form.not_email')
}

export function makeMinLen(len) {
  return value =>
    value && value.length >= len
      ? undefined
      : i18n.getFixedT(null, 'common')('form.min_length', { len })
}

export function makeMaxLen(len) {
  return value =>
    value && value.length <= len
      ? undefined
      : i18n.getFixedT(null, 'common')('form.max_length', { len })
}

export function isChecked(value) {
  return value ? undefined : i18n.getFixedT(null, 'common')('form.not_selected')
}

export function isSelected(value) {
  return value ? undefined : i18n.getFixedT(null, 'common')('form.not_selected')
}

export function makeIsEqual(first, second, message) {
  return (_, allValues) =>
    allValues[first] === allValues[second] ? undefined : message
}

export function makeIsGreater(min) {
  return value =>
    value > min ? undefined : i18n.getFixedT(null, 'common')('form.not_secure')
}

export function isEmpty(value) {
  return value ? undefined : i18n.getFixedT(null, 'common')('form.not_empty')
}

export function isURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+={}-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return !!pattern.test(str)
    ? undefined
    : i18n.getFixedT(null, 'common')('form.not_url')
}

export function isPhoneNumber(str) {
  var pattern = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$')
  return !!pattern.test(str)
    ? undefined
    : i18n.getFixedT(null, 'common')('form.not_phone')
}

export function isName(str) {
  var pattern = new RegExp(`^([a-zA-Z]+?)([-\s'][a-zA-Z]+)*?$`)
  return !!pattern.test(str) ? undefined : 'error'
}

export function isFirstName(str) {
  return isName(str) === 'error'
    ? i18n.getFixedT(null, 'common')('form.not_first_name')
    : undefined
}

export function isLastName(str) {
  return isName(str) === 'error'
    ? i18n.getFixedT(null, 'common')('form.not_last_name')
    : undefined
}

export function isURLWithProtocol(str) {
  var pattern = new RegExp(
    '^(http://|https://){1}[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
    'i'
  )

  return !!pattern.test(str)
    ? undefined
    : i18n.getFixedT(null, 'common')('form.not_url')
}

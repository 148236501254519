import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Section from 'presentational/Section'
import { NotificationContext } from 'functional/NotificationProvider'

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

const ModalSectionContent = styled.div`
  max-width: 450px;

  margin: 15px;
`

class Modal extends React.Component {
  render() {
    return (
      <NotificationContext.Consumer>
        {context => {
          const { isOpen, component } = context.state.modal
          if (!isOpen) return
          return (
            <ModalWrapper>
              <Section>
                <ModalSectionContent>
                  {this.props.modalSlot()}
                </ModalSectionContent>
              </Section>
            </ModalWrapper>
          )
        }}
      </NotificationContext.Consumer>
    )
  }
}

Modal.propTypes = {
  modalSlot: PropTypes.func,
}

export default Modal

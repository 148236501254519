import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import NewPasswordFormSection from 'functional/NewPasswordFormSection'
import Button from 'presentational/Button'
import AuthFormLoader from 'presentational/AuthFormLoader'
import FormError from 'presentational/FormError'

import { makePasswordStrengthField } from 'util'

const passwordStrengthDecorator = makePasswordStrengthField()

const SetNewPasswordForm = props => {
  const { t } = useTranslation('common')

  return (
    <Form
      decorators={[passwordStrengthDecorator]}
      onSubmit={props.onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <NewPasswordFormSection />
          {submitting && <AuthFormLoader />}
          {submitError && <FormError>{submitError}</FormError>}
          <Button submit disabled={submitting} size="large">
            {t('login_register.set_new_password_button')}
          </Button>
        </form>
      )}
    />
  )
}

SetNewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

/** @component */
export default SetNewPasswordForm

import i18n from '../translation/i18n'

export const formatDate = date => {
  const months = [
    i18n.getFixedT(null, 'common')('default.january'),
    i18n.getFixedT(null, 'common')('default.february'),
    i18n.getFixedT(null, 'common')('default.march'),
    i18n.getFixedT(null, 'common')('default.april'),
    i18n.getFixedT(null, 'common')('default.may'),
    i18n.getFixedT(null, 'common')('default.june'),
    i18n.getFixedT(null, 'common')('default.july'),
    i18n.getFixedT(null, 'common')('default.august'),
    i18n.getFixedT(null, 'common')('default.september'),
    i18n.getFixedT(null, 'common')('default.october'),
    i18n.getFixedT(null, 'common')('default.november'),
    i18n.getFixedT(null, 'common')('default.december'),
  ]
  date = new Date(date)
  let day = date.getDate()
  let month = date.getMonth()
  let year = date.getFullYear()

  return `${day}. ${months[month]} ${year}`
}

export const currentTimeFormated = () => {
  const date = new Date()

  const day = date.getDate()
  const year = date.getUTCFullYear()
  const month = date.getMonth() + 1 + ''

  return year + '' + month.padStart(2, '0') + '' + day
}

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  overflow-y: scroll;

  background-color: ${props => props.theme.colors.backgrounds.dark};
`
const WidthLimit = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '420px')};
  padding: 10vh 20px;
`

const LanguageSwitcher = styled.div`
  position: absolute;
  top: 10px;
  right: 40px;
  color: white;
  display: flex;
`

const Language = styled.p`
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  color: ${props => props.theme.colors.text.dark};
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;

  :hover,
  :any-link:hover {
    color: #fff;
  }

  :any-link,
  :any-link:active,
  :-webkit-any-link,
  :-webkit-any-link:active {
    color: ${props => props.theme.colors.text.label};
  }
`

const Spacer = styled.span`
  width: 2px;
  background: ${props => props.theme.colors.text.label};
  height: 1005;
  margin: 2px 5px;
`

/**
 * A layout component for auth related screens
 * @author Stjepan Golemac
 */
const AuthLayout = props => {
  const { t, i18n } = useTranslation('common')

  return (
    <Center>
      <WidthLimit maxWidth={props.maxWidth}>{props.children}</WidthLimit>
      <LanguageSwitcher>
        <Language onClick={() => i18n.changeLanguage('german')}>De</Language>
        <Spacer />
        <Language onClick={() => i18n.changeLanguage('english')}>En</Language>
      </LanguageSwitcher>
    </Center>
  )
}

/** @component */
export default AuthLayout

import styled from 'styled-components'

/**
 * This is a H4 component.
 * @author Stjepan Golemac
 */
const H4 = styled.h4`
  font-weight: bolder;
  font-size: 1em;
  color: ${props => props.theme.colors.text.normal};
`

/** @component */
export default H4

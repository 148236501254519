import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form } from 'react-final-form'

import Button from 'presentational/Button'
import FormError from 'presentational/FormError'
import PrimaryButton from 'presentational/PrimaryButton'
import AuthFormLoader from 'presentational/AuthFormLoader'

import EmailField from 'functional/EmailField'

const AuthFormLoaderWithMargin = styled(AuthFormLoader)`
  margin-top: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;

  margin: 0px -2.5px;
  margin-top: 20px;

  button {
    margin: 0 2.5px;
  }
`
/**
 * @description Form that accepts a email address to change the mail of a user.
 * @param onSubmit Called when user submits email change.
 * @param onClose Called when user cancels form.
 */
const ChangeEmailForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation('common')
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <EmailField
            name="email"
            label={t('profile.change_email_modal_label')}
            noInline
          />
          {submitting && <AuthFormLoaderWithMargin />}
          {submitError && <FormError>{submitError}</FormError>}
          <ButtonWrapper>
            <PrimaryButton submit disabled={submitting}>
              {t('profile.change_email_modal_submit')}
            </PrimaryButton>
            <Button key={0} onClick={onClose} disabled={submitting}>
              {t('default.cancel')}
            </Button>
          </ButtonWrapper>
        </form>
      )}
    />
  )
}

ChangeEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

ChangeEmailForm.defaultProps = {
  onClose: () => {},
}

/** @component */
export default ChangeEmailForm

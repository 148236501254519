import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import H3 from 'presentational/H3'
import Button from 'presentational/Button'
import Paragraph from 'presentational/Paragraph'

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
`

const ButtonLeft = styled(Button)`
  margin-right: 5px;
`

const ButtonRight = styled(Button)`
  margin-left: 5px;
`

/**
 * @author Jan Mägdefrau
 * @description Modal that will ask the user to confirm a bulk operation
 *
 * @param onBulk Function that is called when user accepts bulk operation
 * @param onClose Function that is called when user closes modal without accept.
 * @param bulkOperation String which operation will be perform
 * @param selected Integer how many elements will be affected.
 */
const ConfirmBulkOpModal = ({ onBulk, onClose, bulkOperation, selected }) => {
  const { t } = useTranslation('common')

  return (
    <React.Fragment>
      <H3>{t('bulk_operation.modal_title')}</H3>
      <Paragraph>
        <Trans t={t} i18nKey="bulk_operation.modal_description">
          <strong>
            {{
              operation: bulkOperation,
            }}
          </strong>
          <strong>
            {{
              selected_count: selected,
            }}
          </strong>
        </Trans>
      </Paragraph>
      <ButtonWrapper>
        <ButtonLeft
          onClick={() => {
            onBulk()
            onClose()
          }}
        >
          {t('bulk_operation.modal_submit')}
        </ButtonLeft>
        <ButtonRight kind="secondary" onClick={onClose} type="nobg">
          {t('default.cancel')}
        </ButtonRight>
      </ButtonWrapper>
    </React.Fragment>
  )
}

ConfirmBulkOpModal.propTypes = {
  onBulk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: PropTypes.number,
  bulkOperation: PropTypes.string,
}

ConfirmBulkOpModal.defaultProps = {
  selected: 0,
  bulkOperation: '',
}

/** @component */
export default ConfirmBulkOpModal

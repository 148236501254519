import React from 'react'
import { Provider } from 'unstated'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import theme from 'theme/theme'

import Logout from 'functional/Logout'
import LoginScreen from 'functional/LoginScreen'
import ForgotPasswordScreen from 'functional/ForgotPasswordScreen'
import SetNewPasswordScreen from 'functional/SetNewPasswordScreen'
import ConfirmNewEmailScreen from 'functional/ConfirmNewEmailScreen'

import LoggedInAdminSubroutes from './LoggedInAdminSubroutes'

const AdminRouter = () => (
  <Provider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/forgot-password"
            component={ForgotPasswordScreen}
          />
          <Route
            exact
            path="/set-new-password/:id/:token"
            component={SetNewPasswordScreen}
          />
          <Route
            exact
            path="/confirm-email/:id/:email/:token"
            component={ConfirmNewEmailScreen}
          />
          <Route
            exact
            path="/login"
            render={props => <LoginScreen {...props} noRegistration />}
          />
          <Route exact path="/logout" component={Logout} />
          <Route path="/" component={LoggedInAdminSubroutes} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)

export default AdminRouter
